// JavaScript source code
import "./styles/ResetPassword.css";
import { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PasswordCheck } from './Utilities';

function ResetPassword() {
    const [passwordLengthCheck, setPasswordLengthCheck] = useState(false);
    const [specialCharCheck, setSpecialCharCheck] = useState(false);
    const [capitalLetterCheck, setCapitalLetterCheck] = useState(false);
    const [lowerLetterCheck, setLowerLetterCheck] = useState(false);
    const [numberCheck, setNumberCheck] = useState(false);
    const [code, setCode] = useState();
    const [email, setEmail] = useState();
    const [queryParams] = useSearchParams();
    const [password, setPassword] = useState();
    const testPassword = useRef();
    const [confirmPassword, setConfirmPassword] = useState();

    useEffect(() => {
        if (queryParams.get('code') !== undefined) {
            setCode(queryParams.get('code'));
        }
        if (queryParams.get('email') !== undefined) {
            setEmail(queryParams.get('email'));
        }
    }, [queryParams]);

    function ResetPassword(event) {
        event.preventDefault();

        var URL = process.env.REACT_APP_NODE_ADDRESS + "/ChangePassword";

        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'password': password, 'code': code, 'email': email })
        };

        fetch(URL, idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    window.location.assign('/login');
                }
            });
    }

    return (
        <form className="resetPasswordForm">
            <label htmlFor="code">Reset Code</label> <br />
            <input type="number" id="code" name="code" defaultValue={code} /><br />
            <label htmlFor="password">Password</label><br />
            <input type="password" id="password" name="password" required onChange={(e) => { testPassword.current = e.target.value; setPassword(testPassword.current); PasswordCheck(testPassword.current, setLowerLetterCheck, setCapitalLetterCheck, setSpecialCharCheck, setNumberCheck, setPasswordLengthCheck); }} /><br />
            <div className="passwordResetRequirements">
                <span style={{ color: (passwordLengthCheck ? 'lightgreen' : 'red') }}><p className="passwordRequirementMark">{passwordLengthCheck ? '\u2713' : '\u2179'}</p><p className="passwordRequirementField">Password must be at least 8 characters</p></span>
                <span style={{ color: (lowerLetterCheck ? 'lightgreen' : 'red') }}><p className="passwordRequirementMark">{lowerLetterCheck ? '\u2713' : '\u2179'}</p><p className="passwordRequirementField">Password must have a lowercase letter</p></span>
                <span style={{ color: (capitalLetterCheck ? 'lightgreen' : 'red') }}><p className="passwordRequirementMark">{capitalLetterCheck ? '\u2713' : '\u2179'}</p><p className="passwordRequirementField">Password must have a capital letter</p></span>
                <span style={{ color: (specialCharCheck ? 'lightgreen' : 'red') }}><p className="passwordRequirementMark">{specialCharCheck ? '\u2713' : '\u2179'}</p><p className="passwordRequirementField">Password must have a special character</p></span>
                <span style={{ color: (numberCheck ? 'lightgreen' : 'red') }}><p className="passwordRequirementMark">{numberCheck ? '\u2713' : '\u2179'}</p><p className="passwordRequirementField">Password must have a number</p></span>
            </div>
            <label htmlFor="confirmPassword">ConfirmPassword</label><br />
            <input type="password" id="confirmPassword" name="confirmPassword" required onChange={(e) => { setConfirmPassword(e.target.value); }} /><br />
            {confirmPassword !== password && <p className="passResetMatch">Passwords Must Match</p>}
            <button className="submitPasswordReset" onClick={ResetPassword}>Change Password</button>
        </form>
    );
}

export default ResetPassword;
