// JavaScript source code
import { useEffect, useState } from 'react';
import './styles/OutreachEmail.css';
import { render } from "@react-email/render";
import PathlessEmail from './Emails/pathless-email';

function OutreachEmail() {
    const [emailBody, setEmailBody] = useState();

    useEffect(() => {
        setEmailBody(render(PathlessEmail()));
    }, [])

    function updateBody(event) {
        event.preventDefault();
        setEmailBody(event.target.value);
    }

    return (
        <div>
            <div className="emailBodyDiv">
                <textarea className="emailBodyArea" defaultValue={emailBody} onChange={updateBody} rows="50"></textarea>
            </div>
            <div dangerouslySetInnerHTML={{ __html: emailBody}} />
        </div>
        );
}

export default OutreachEmail;
