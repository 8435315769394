// JavaScript source code
import { useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import "./styles/AccountConfirm.css";

function ConfirmAccount() {
    const [queryParams] = useSearchParams();

    async function RedirectToHome() {
        await setTimeout(5000);

        window.location.assign("/");
    }

    useEffect(() => {

        if (queryParams.get("username") !== undefined) {
            const signUpReqOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'username': queryParams.get("username") })
            };

            fetch(process.env.REACT_APP_NODE_ADDRESS + '/ConfirmSignUp', signUpReqOptions)
                .then((res) => res.json())
                .then((data) => {
                    if (data.success === true) {
                        //setConfirmSignUp(true);
                        RedirectToHome();
                    }
                });
        }

    }, [queryParams]);

    return (
        <div className="confirmPage">
            <h1>Your account is approved!</h1>
        </div>
    );
}

export default ConfirmAccount;
