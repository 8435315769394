// JavaScript source code
import './styles/PlayerInterest.css';
import React, { useState, useEffect } from "react";

function PlayerInterest() {
    const [email, setEmail] = useState();
    const [error, setError] = useState();
    const [submitted, setSubmitted] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'email': email })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/PlayerEmailSignUp", reqOptions)
            .then((res) => res.json())
            .then((data) => {
                //console.log(data);
                setSubmitted(data.success);
                if (data.success === false) {
                    setError(data.message.code);
                }
            });
    };

    return (
        <div className="playerInterestForm">
            {submitted === false && <div>
                <p className="playerInterError">{error}</p>
                <form id="playerInterForm" onSubmit={onSubmit}>
                    <label htmlFor="email">Email: </label> <br/>
                    <input required type="email" id="email" name="email" onChange={(e) => setEmail(e.target.value)} /> <br /> <br />
                    <input className="playerSubmit" name="submit" type="submit" value="Submit"/>
                </form>
            </div>}
            {submitted === true && <p className="playerEmailSuccess">Email submitted!</p>}
        </div>
    );
}

export default PlayerInterest;
