// JavaScript source code
import { useEffect } from "react";
import "./styles/ParentalApproval.css";
import { useSearchParams } from "react-router-dom";
import { ParentalApprovalEmail } from "./Emails/ParentalApprovalEmail";
import { render } from '@react-email/render';

function ParentalApproval() {
    const [queryParams] = useSearchParams();

    function ApproveAccount() {
        var URL = process.env.REACT_APP_NODE_ADDRESS + "/ApproveAccount";


        if (queryParams.get("username") !== undefined) {
            const reqOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'username': queryParams.get("username") })
            };

            fetch(URL, reqOptions)
                .then((res) => res.json())
                .then((data) => {

                    if (data.success === true) {
                        window.location.assign("/");
                    }

                });
        }
    }

    /*useEffect(() => {
        var URL = process.env.REACT_APP_NODE_ADDRESS + "/TestParentalEmail";

        var message = render(ParentalApprovalEmail(50000));

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': 50000, 'email': "matt@pathlessproductions.com", 'message': message })
        };

        console.log(message);

        fetch(URL, reqOptions)
            .then((res) => res.json())
            .then((data) => {
                console.log(data);

                if (data.success === true) {
                    //window.location.assign("/");
                }

            });

    }, []);*/

    return (
        <div className="parentalApprovalDev">
            <p>Approve request for your child's account</p>
            <button onClick={ApproveAccount}>Approve</button>
        </div>
    );
}

export default ParentalApproval;
