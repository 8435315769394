// JavaScript source code
import "./styles/SignUpConfirmation.css";
import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

function SignUpConfirmation() {
    const [confirmationCode, setConfirmationCode] = React.useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [confirmed, setConfirmed] = React.useState(false);
    //const [confirmationCode, setConfirmationCode] = React.useState("");

    /*const submitCode = (event) => {
        event.preventDefault();

        var confirm = searchParams.get("code");

        if (confirm !== undefined && confirm !== "") {
            setConfirmationCode(confirm);
        }

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'confirmationCode': confirmationCode, 'username': searchParams.get("username")})
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/ConfirmSignUp', reqOptions)
            .then((res) => res.json())
            .then((data) => {
                setConfirmed(true);
            });
    };*/

    return (
        <div className="confirmationMainPage">
            <div className="confirmationMessage">
                <h1>Confirm account via email.</h1>
            </div>
            {confirmed && <Navigate to='/' />}
        </div>
    );
}

export default SignUpConfirmation;
