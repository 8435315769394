// JavaScript source code
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Header from './Header';
import { useSearchParams } from "react-router-dom";

var params = [];
params[0] = "userid";
params[1] = "gameid";
params[2] = "event";

function RegisterGameEvent() {
    const [message, setMessage] = useState("");
    const [queryParams] = useSearchParams();

    var URL = "http://3.135.195.199:8000/RegisterGameEvent?";
    URL += "userid=" + queryParams.get("userid") + '&';
    URL += "gameid=" + queryParams.get("gameid") + '&';
    URL += "event=" + queryParams.get("event");
    /*for (var i = 0; i < params.length; i++) {
        if (i > 0) URL += '&';
        URL += params[i] + '=' + queryParams[params[i]];
    }*/

    useEffect(() => {
        fetch(URL)
            .then((res) => res.json())
            .then((data) => setMessage(data.message));
    }, []);

    return (<p>{message}</p>);
}

export default RegisterGameEvent;