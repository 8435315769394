// JavaScript source code
import {
    Body,
    Button,
    Container,
    Head,
    Hr,
    Html,
    Img,
    Preview,
    Section,
    Text,
} from '@react-email/components';
import React from 'react';

export function AccountConfirmation(username) {

    return (
        <Html style={docStyle }>
            <Preview>
                Confirm your Ludare email now.
            </Preview>
            <body>
                <Container>
                    <Img style={imgStyle} src="https://www.devpowered.com/LudareLogo.png" />
                    <Text>
                        {'Hi ' + username + '!'}
                    </Text>
                    <Text>
                        Someone has requested to create an account with Ludare using this email. Ludare is the premier platform to support the developers of the games you love just by playing.
                    </Text>
                    <Text>
                        If this was you, please click the button below to verify your account.
                    </Text>
                    <Button style={buttonStyle} href={process.env.REACT_APP_BASE_ADDRESS + "/ConfirmAccount?username=" + username}>
                        Verify
                    </Button>
                    <Text>
                        If this was not you, please let us know and we will delete the account.
                    </Text>
                </Container>
            </body>
        </Html>
    );
};

const docStyle = {
    width: '800px',
    height: 'fit-content',
    padding: '10px',
    margin: 'auto',
    background: 'white'
}

const buttonStyle = {
    width: '100%',
    height: '40px',
    background: '#ffcf00',
    color: 'black',
    margin: 'auto',
    'text-align': 'center',
    'line-height': '40px',
    'content-align': 'center',
    'padding-top': '15px'
}

const imgStyle = {
    width: '100px',
    height: '100px',
    margin: 'auto'
}