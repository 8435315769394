// JavaScript source code
import "./styles/ForgotPassword.css";
import { useState } from 'react';
import { PasswordReset } from './Emails/PasswordReset';
import { render } from '@react-email/render';

function ForgotPassword() {
    const [email, setEmail] = useState();
    const [submitted, setSubmitted] = useState(false);

    function ResetPassword(event) {
        event.preventDefault();

        var URL = process.env.REACT_APP_NODE_ADDRESS + "/ForgotPassword";
        var code = (Math.round(Math.random() * 899999) + 100000);

        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'emailBody': render(PasswordReset(email, code)), 'code': code, 'email': email })
        };

        fetch(URL, idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    setSubmitted(true);
                    SleepThenRedirect();
                }
            });
    }

    async function SleepThenRedirect() {
        await setTimeout(5000);
        window.location.assign('/login');
    }

    return (
        < div className="resetPage" >
            {submitted !== true && <form className="resetForm" onSubmit={ResetPassword}>
                <label htmlFor="email">Email</label><br />
                <input className="resetTextInput" type="email" id="email" name="email" required onChange={(e) => setEmail(e.target.value)} /> <br/>
                <input className="resetSubmit" name="submit" type="submit" value="Submit" onClick={ResetPassword} />
            </form>}
            {submitted === true &&
                <div className="submitedTextContainter">
                    <p>Request submitted.</p> <br />
                    <p>Check your email for next steps.</p>
                </div>
            }
        </div >
    );
}

export default ForgotPassword;
