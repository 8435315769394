// JavaScript source code
import { useRef, useState } from 'react';
import GameImageHeader from './GameImageHeader';
import './styles/PlayerInfo.css';

function PlayerInfo() {

    return (
        <div className="playerPage">
            <div className="titleLinePlayer">
                <GameImageHeader />
                <div className="topBodyPlayer">
                    <div className="infoPlayer">
                        <h2 className="taglineTopPlayer">Support A New Favorite Game</h2>
                        <h2 className="taglineBottomPlayer">Just By Playing It</h2>
                        <p className="subTagLinePlayer">Try a bunch of new games for one price.<br/>Continue to support by playing your favorites.</p>
                    </div>
                </div>
            </div>
            <div className="playerInfoContainer">
                <h1 className="playerSectionHeader">Start Playing in 3 Easy Steps</h1>
                <div className="signUpStep">
                    <div className="signUpTile">
                        <img className="signUpIcon" src="pc-recolored.png" />
                        <p className="signUpTileInfo">To support your favorite indie game, sign up on our website so that you can have an account to track your access to the titles in the libary.</p>
                    </div>
                    <div className="signUpMap">
                        <h1 className="signupStepNum">01.</h1>
                        <p className="signUpStepTitle">CREATE AN ACCOUNT</p>
                    </div>
                </div>

                <div className="paymentStep">
                    <div className="paymentMap">
                        <h1 className="paymentStepNum">02.</h1>
                        <p className="paymentStepTitle">SET UP YOUR SUBSCRIPTION</p>
                    </div>
                    <div className="paymentTile">
                        <img className="paymentIcon" src="banknote-recolored.png" />
                        <p className="paymentTileInfo">Next, set up recurring payments via Stripe to get access to the games in the library. There is a minimum price to get access to the games in the library to ensure enough revenue is moving through the system. However, you can set your subscription at any level above that price and it will be distributed in the same way.</p>
                    </div>
                </div>
                <div className="playStep">
                    <div className="playTile">
                        <img className="playIcon" src="gamepad-recolored.png" />
                        <p className="playTileInfo">Download any game from our library, unpack it, boot up the game, and sign in. We will then automatically track your playtime and distribute your subscription to the developers.</p>
                    </div>
                    <div className="playMap">
                        <h1 className="playStepNum">03.</h1>
                        <p className="playStepTitle">BOOT UP ANY GAME</p>
                    </div>
                </div>*/
            </div>
        </div>
    );
}

export default PlayerInfo;