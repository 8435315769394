// JavaScript source code
import "./styles/AccountPage.css";
import { Navigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import crypto from 'crypto-js';
import Cookies from 'js-cookie';
import { useSearchParams } from "react-router-dom";
import LudareCheckoutForm from "./CheckoutForm";
import Popup from 'reactjs-popup';
import SetupSubscription from "./SetupSubscription";
import LoadingPopup from './LoadingPopup';
import { GetAccessToken, CheckUsername, IsValid} from "./Utilities";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import ReactGA from 'react-ga4';
import AWS from "aws-sdk";
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { XhrHttpHandler } from '@aws-sdk/xhr-http-handler';
import { Upload } from '@aws-sdk/lib-storage';
import GameCard from './components/library/Game.js';
//import loadingGif from '/public/';

ReactGA.initialize('G-P1CP4P4GZ6');

function AccountPage({ status }) {
    const [accountInfo, setAccountInfo] = useState();
    const [queryParams] = useSearchParams();
    const [pageStatus, setPageStatus] = useState("");
    const [currDevId, setCurrDevId] = useState();
    const [gameArray, setGameArray] = useState([]);
    const [devArrayCount, setDevArrayCount] = useState(0);
    const [developerArray, setDeveloperArray] = useState([]);
    const [currGame, setCurrGame] = useState();
    const tempDevArray = useRef([]);
    const targetDevID = useRef();
    //const tempGameArray = useRef([]);

    function SubscriptionClick() {
        setCurrDevId(undefined);
        setPageStatus("Subscription");
    }

    function LinkAccountsClick() {
        setCurrDevId(undefined);
        setPageStatus("LinkAccounts");
    }

    function AccountClick() {
        setCurrDevId(undefined);
        setPageStatus("Account");
    }

    useEffect(() => {
        

        FetchAccountInfo(setAccountInfo);

        if (queryParams.get("steamID") !== undefined && queryParams.get("steamID") !== null) {
            GetAccessToken().then((token) => {
                const idReqOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'token': token, 'ID': queryParams.get("steamID"), 'type': "steam" })
                };

                fetch(process.env.REACT_APP_NODE_ADDRESS + "/RegisterID", idReqOptions)
                    .then((res) => res.json())
                    .then((data) => { FetchAccountInfo(setAccountInfo); });
            });
        }

        if (queryParams.get("epicID") !== undefined && queryParams.get("epicID") !== null) {
            GetAccessToken().then((token) => {
                const idReqOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 'token': token, 'ID': queryParams.get("epicID"), 'type': "epic" })
                };

                fetch(process.env.REACT_APP_NODE_ADDRESS + "/RegisterID", idReqOptions)
                    .then((res) => res.json())
                    .then((data) => { FetchAccountInfo(setAccountInfo); });
            });
        }

        if (queryParams.get("loadPage") !== null) {
            setPageStatus(queryParams.get("loadPage"));
        }

        if (queryParams.get("loadDev") !== null) {
            targetDevID.current = queryParams.get("loadDev");
        }

        if (queryParams.get("loadGame") !== null) {
           setCurrGame(queryParams.get("loadGame"));
        }

    }, [queryParams]);

    useEffect(() => {
        if (accountInfo === undefined || accountInfo === null) {
            return;
        }

        if (accountInfo.DeveloperOn !== null && accountInfo.DeveloperOn.length > 0) {

            var devIds = JSON.parse(accountInfo.DeveloperOn);

            if (devIds !== undefined && devIds !== null) {
                devIds.map((developerId, index) => {
                    var URL = process.env.REACT_APP_NODE_ADDRESS + "/GetDeveloperInfo";

                    const reqOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 'developerId': developerId })
                    };

                    fetch(URL, reqOptions)
                        .then((res) => res.json())
                        .then((data) => {

                            var newDevArray = tempDevArray.current;

                            var lastIndex = -1;

                            if (data.message === undefined) {
                                return;
                            }

                            if (targetDevID.current === data.message.DeveloperID) {
                                setCurrDevId(targetDevID.current);
                            }

                            for (var i = 0; i < newDevArray.length; i++) {
                                if (newDevArray[i].DeveloperID === data.message.DeveloperID) {
                                    lastIndex = i;
                                }
                            }

                            if (lastIndex === -1) {
                                newDevArray = [...newDevArray, data.message];
                            }

                            tempDevArray.current = newDevArray;

                            setDeveloperArray(tempDevArray.current);
                            setDevArrayCount(newDevArray.length);
                        });
                });
            }
        }
    }, [accountInfo])

    useEffect(() => {
        setCurrDevId(targetDevID.current);
    }, [targetDevID, developerArray]);

    useEffect(() => {

        var devInfo;
        for (var i = 0; i < developerArray.length; i++) {
            if (currDevId !== undefined && currDevId === developerArray[i].DeveloperID) {
                devInfo = developerArray[i];
                break;
            }
        }

        var newGamesArray = [];

        if (devInfo === undefined || devInfo === null) return;

        var games = JSON.parse(devInfo.Game);

        if (games !== undefined && games !== null) {
            games.map((gameID, Index) => {

                if (newGamesArray.indexOf(gameID) === -1) {
                    newGamesArray = [...newGamesArray, gameID];

                }
                return true;
            });
        }

        setGameArray(newGamesArray);

    }, [currDevId, developerArray])

    function DeveloperListArray() {

        return (
            <div className="devArrayDiv">
                {devArrayCount > 0 && gameArray.map !== null && (developerArray.map((DeveloperInfo, index) => (
                    <DeveloperListItem developerInfo={DeveloperInfo} setPage={setPageStatus} setDevId={setCurrDevId} setCurrGame={setCurrGame} currDevId={currDevId} />
                )))}
            </div>
        );
    }

    return (
        <div className="accountPage">
            {!status && <Navigate to='/' />}
            <ul className="sideMenu">
                <li><p className="personalHeader">Personal</p></li>
                <ul className="personalMenu">
                    <li><button value="Account" onClick={AccountClick} style={{ background: ((pageStatus === "" || pageStatus === "Account") ? 'rgba(100, 100, 100, 1)' : 'rgba(0, 0, 0, 0)' )}}>Account</button></li>
                    <li><button value="LinkAccounts" onClick={LinkAccountsClick} style={{ background: (pageStatus === "LinkAccounts" ? 'rgba(100, 100, 100, 1)' : 'rgba(0, 0, 0, 0)') }}>Link Accounts</button></li>
                    <li><button value="Subscription" onClick={SubscriptionClick} style={{ background: (pageStatus === "Subscription" ? 'rgba(100, 100, 100, 1)' : 'rgba(0, 0, 0, 0)') }}>Subscription</button></li>
                </ul>
                <li><hr/></li>
                <li><p className="developerHeader">Developer</p></li>
                <ul className="developerMenu">
                    {accountInfo !== undefined && devArrayCount > 0 && <DeveloperListArray />}
                    <DeveloperListItem setPage={setPageStatus} setDevId={setCurrDevId} setCurrGame={setCurrGame} currDevId={currDevId} setCurrDevId={setCurrDevId} currPage={pageStatus} />
                </ul>
            </ul>
            <div className="verticalBreak" />
            <div className="infoPane">
                {(pageStatus === "" || pageStatus === "Account") && <BasicAccountInfo accountInfo={accountInfo} setAccountInfo={setAccountInfo} />}
                {pageStatus === "Subscription" && <SubscriptionInfo accountInfo={accountInfo} setAccountInfo={setAccountInfo} />}
                {pageStatus === "LinkAccounts" && <ConnectedAccountInfo accountInfo={accountInfo} setAccountInfo={setAccountInfo} />}
                {pageStatus === "Developer" && <DeveloperPage devId={currDevId} accountInfo={accountInfo} gameArray={gameArray} devArray={developerArray} currGame={currGame} setCurrGame={setCurrGame} /> }
                {pageStatus === "NewDeveloper" && <NewDeveloperPage accountInfo={accountInfo} />}
            </div>
        </div>
    );
}

export default AccountPage;

function BasicAccountInfo({ accountInfo, setAccountInfo }) {
    //console.log(accountInfo);
    const [displayName, setDisplayName] = useState();
    const [usernameCheck, setUsernameCheck] = useState(undefined);
    const [usernameCheckRes, setUsernameCheckRes] = useState("");
    var tempDisplay = "";

    useEffect(() => {
        if (accountInfo !== null && accountInfo !== undefined) {
            setDisplayName((accountInfo.DisplayName !== null && accountInfo.DisplayName !== undefined) ? accountInfo.DisplayName : accountInfo.Username);
        }
    }, [accountInfo]);

    function UpdateDisplayName() {
        if (usernameCheck === false) return;

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'username': accountInfo.username, 'newName': tempDisplay })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/UpdateDisplayName', reqOptions)
            .then((res) => res.json())
            .then((data) => {
                /*if (data.success === false) {
                    return;
                }
                if (data.result === true) {
                    resultString("Username is not taken");
                }
                else {
                    resultString("Username is already taken");
                }*/

                FetchAccountInfo(setAccountInfo);
            });
    }

    function CheckCurrentUsername(event) {
        event.preventDefault();

        CheckUsername(tempDisplay, setUsernameCheck, setUsernameCheckRes);
    }
    
    const ModifyUsernamePopup = ({ accountInfo }) => (
        <Popup trigger={<button className="modifyUsername">Update</button>} modal nested lockScroll >
            {close => (
                <form className="displayNameEditForm">
                    <label className="editNameLabel" htmlFor="displayName">Display Name:</label>
                    <input type="text" id="displayName" name="displayName" onChange={(e) => { e.preventDefault(); tempDisplay = e.target.value; }} />
                    <div className="confirmUsername"><button className="checkName" onClick={CheckCurrentUsername}>Check DisplayName</button> {usernameCheck !== undefined && <p className="usernameCheckRes" style={{ color: (usernameCheck === true) ? "lightgreen" : "red" }}>{usernameCheckRes}</p>} </div>
                    <div className="displayNameEditButtons"> <button className="confirmEdit" onClick={UpdateDisplayName}>Update Display Name</button> <button className="cancelEdit" onClick={(event) => { event.preventDefault(); close(); }}>Cancel</button> </div>
                </form>
            )}
        </Popup>
    );

    return (
        <div>
            {accountInfo !== undefined && (<div className="accountInfoPane" >
                <div className="usernameBlock">
                    <p className="accountUsername">Username:</p>
                    <div className="modifyUsernameBlock">
                        <p> {displayName} </p>
                        
                    </div>
                </div>
                <hr />
                <div className="emailBlock">
                    <p className="accountEmail">email:</p>
                    <p className="emailField">{accountInfo.Email}</p>
                </div>
            </div>)}
        </div>

    );
}

//<ModifyUsernamePopup accountInfo={accountInfo } />

function ConnectedAccountInfo({ accountInfo, setAccountInfo }) {
    //console.log(accountInfo.EpicID);

    useEffect(() => {
        
    }, []);

    return (
        <div className="accountLinks">
            {accountInfo !== undefined && <div className="steamLink">
                <a href={process.env.REACT_APP_NODE_ADDRESS + "/auth/steam?userID=" + accountInfo.LudareID}>
                    <img className="platformImg" src="SteamLoginImage.png" alt="Steam required login icon for external websites" />
                </a> <br />
                <p className="platformLabel"> Steam </p> <br />
                <p style={{ color: (accountInfo.SteamID !== null && accountInfo.SteamID !== undefined) ? "lightgreen" : "red" }}> Status: {(accountInfo.SteamID && accountInfo.SteamID !== undefined) !== null ? "Linked" : "Not Linked"} </p>
            </div>}
            {accountInfo !== undefined && <div className="epicLink">
                <a href={"https://www.epicgames.com/id/authorize?client_id=xyza7891yUZ9T5c0Ei459DIUyKn7vglR&response_type=code&scope=basic_profile&redirect_uri=https://www.devpowered.com:8000/auth/epic"}>
                    <img className="platformImg" src="epic_games_black_logo.png" alt="Steam required login icon for external websites" />
                </a> <br />
                <p className="platformLabel"> Epic </p> <br />
                <p style={{ color: (accountInfo.EpicID !== null && accountInfo.EpicID !== undefined) ? "lightgreen" : "red" }}> Status: {(accountInfo.EpicID !== null && accountInfo.EpicID !== undefined) ? "Linked" : "Not Linked"} </p>
            </div>}
            {accountInfo !== undefined && <div className="googleLink">
                <a href={"https://accounts.google.com/o/oauth2/v2/auth?client_id=289770615480-dk7l2cnsr9jqecgtidmtbfor5gcalagh.apps.googleusercontent.com&response_type=code&scope=openid%20email&redirect_uri=" + process.env.REACT_APP_BASE_ADDRESS + "/GoogleReturn"}>
                    <img src="Android.png" alt="Steam required login icon for external websites" />
                </a> <br />
                <p className="platformLabel"> Android </p> <br />
                <p style={{ color: (accountInfo.GoogleID !== null && accountInfo.GoogleID !== undefined) ? "lightgreen" : "red" }}> Status: {(accountInfo.GoogleID !== null && accountInfo.GoogleID !== undefined) ? "Linked" : "Not Linked"} </p>
            </div>}
        </div>

    );
}

function SubscriptionInfo({ accountInfo, setAccountInfo }) {
    const [selectedPayment, setSelectedPayment] = useState(undefined);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [showPayments, setShowPayments] = useState(false);
    const [subscription, setSubscription] = useState(undefined);
    const [subscriptionAmount, setSubscriptionAmount] = useState(0);
    const [newSubscriptionAmount, setNewSubscriptionAmount] = useState(0);
    const [subscriptionPopupClosed, setSubscriptionPopupClosed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadTillValid, setLoadTillValid] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [subUpdating, setSubUpdating] = useState(false);
    var tempSubscription;

    useEffect(() => {
        if (accountInfo !== undefined) {
            GetSubscription();
            GetPaymentMethods();
        }
    }, [accountInfo]);

    useEffect(() => {
        console.log("Sub Updated");

        if (subscription !== undefined) {
            setLoading(false);
            GetSubscriptionPrice();
            for (var i = 0; i < paymentMethods.length && subscription !== null && subscription !== undefined; i++) {
                if (paymentMethods.id === subscription.default_payment_method) {
                    setSelectedPayment(paymentMethods[i]);
                }
            }
        }
        else {
            if (loadTillValid === true) {
                GetSubscription();
            }
            console.log("No Subscription Set");
        }
    }, [subscription]);

    function GetPaymentMethods() {
        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'customer': accountInfo.StripeId })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/GetPaymentMethods", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    setPaymentMethods(data.paymentMethods);
                    if (data.paymentMethods.length > 0) {
                        setSelectedPayment(data.paymentMethods[0]);
                    }
                }
                else {
                    setSelectedPayment(undefined);
                }
            });
    }

    function GetSubscription() {
        console.log("Get Sub");
        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'customer': accountInfo.StripeId })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/GetSubscription", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setSubscription(data.message);
                }
                else {
                    setSubscription(null);
                }
                console.log("Got Sub");
            });
    }

    function LoadSubscriptionTilValid() {
        setLoading(true);
        setLoadTillValid(true);
        GetSubscription();
    }

    function GetSubscriptionPrice() {
        if (subscription === null || subscription === undefined) return;
        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'price': subscription.items.data[0].price.id })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/GetStripePrice", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setSubscriptionAmount(data.price.unit_amount);
                    setNewSubscriptionAmount(data.price.unit_amount);
                }
            });
    }

    function PaymentMenuOnClick() {
        setShowPayments(!showPayments);
    }

    function PaymentSelected(paymentMethod) {
        setSelectedPayment(paymentMethod);
        setShowPayments(false);
    }

    function NewPaymentSelected() {
        setPopupOpen(true);
        setSelectedPayment(undefined);
        setShowPayments(false);
    }

    function DeletePaymentMethod() {
        setSubUpdating(true);
        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'payment': selectedPayment.id })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/RemovePayment", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                setSubUpdating(false);
                if (data.success) {
                    GetPaymentMethods();
                }
            });
    }

    function StartSubscription(price, paymentMethod) {
        setSubUpdating(true);

        console.log(accountInfo);

        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'customer': accountInfo.StripeId, 'price': price, "paymentSource": paymentMethod.id, "trial": accountInfo.TrialUsed !== 1 }),
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/CreateSubscription", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                setSubUpdating(false);
                if (data.success) {
                    var callback = function () {
                        GetPaymentMethods();
                        LoadSubscriptionTilValid();
                    }

                    ReactGA.gtag('event', 'conversion_event_subscribe_paid', {
                        'event_callback': callback,
                        'event_timeout': 2000,
                    });
                }
            });
    }

    function CheckAndMakeSubscription() {
        if (selectedPayment === undefined || newSubscriptionAmount < 4.99) {
            return;
        }
        else {
            //console.log(selectedPaymentAmount);
            StartSubscription(newSubscriptionAmount, selectedPayment);
        }
        setSubscriptionPopupClosed(true);
    }

    function IsSubActive() {
        return (IsValid(subscription) === true && (subscription.status === "active" || subscription.status === "trialing"));
    }

    function IsSubTrial() {
        return (IsValid(subscription) === true && subscription.status === "trialing");
    }

    function GetNextBillDate() {
        const billDate = new Date(subscription.current_period_end * 1000);
        return billDate.toLocaleDateString();
    }

    function RecommendedSubButtons({ setSubValue1, setSubValue2, setSubValue3 }) {
        return (
            <div className="possibleSubButtons">
                <button className="recommendedAmmount1 accountPageButton" onClick={setSubValue1}> $4.99 </button>
                <button className="recommendedAmmount2 accountPageButton" onClick={setSubValue2}> $6.99 </button>
                <button className="recommendedAmmount3 accountPageButton" onClick={setSubValue3}> $9.99 </button>
                <SetSubscriptionPopup />
            </div>
        );
    }

    function CancelSubscription() {
        setSubUpdating(true);
        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'subscription': subscription.id }),
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/CancelSubscription", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                setSubUpdating(false);
                if (data.success) {
                    setSubscription(undefined);
                }
            });
    }

    function SetDefaultPayment(paymentMethod) {

        if (selectedPayment === null || selectedPayment === undefined) return;

        var postData = { customer: accountInfo.StripeId, paymentMethod: selectedPayment.id };

        if (subscription !== null && subscription !== undefined) {
            postData = { customer: accountInfo.StripeId, paymentMethod: selectedPayment.id, subscription: subscription.id };
        }
        setSubUpdating(true);
        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(postData),
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/SetDefaultPayment", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                setSubUpdating(false);
            });
    }

    function ClosePaymentPopup() {
        setPopupOpen(false);
    }

    function OpenPaymentPopup() {
        setPopupOpen(true);
    }

    const UpdateSubscriptionAmount = (event) => {
        event.preventDefault();
        setNewSubscriptionAmount(tempSubscription * 100);
    }

    const UpdateSubscription = (event) => {
        event.preventDefault();

        if (subscription === null || subscription === undefined) return;

        setSubUpdating(true);
        const idReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ price: newSubscriptionAmount , subscriptionId: subscription.id}),
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/UpdateSubscriptionAmount", idReqOptions)
            .then((res) => res.json())
            .then((data) => {
                setSubUpdating(false);
                if (data.success === true) {
                    setSubscriptionAmount(newSubscriptionAmount);
                }
            });
        
    }

    const AddPaymentPopup = ({ accountInfo }) => (
        <Popup open={popupOpen} onClose={ClosePaymentPopup} modal nested lockScroll>
            <div className="checkoutPopUp">
                <LudareCheckoutForm accountInfo={accountInfo} />
            </div>
        </Popup>
    );

    function RecommendedSub1() {
        setNewSubscriptionAmount(499);
    }

    function RecommendedSub2() {
        setNewSubscriptionAmount(699);
    }

    function RecommendedSub3() {
        setNewSubscriptionAmount(999);
    }

    const SetSubscriptionPopup = ({ accountInfo }) => (
        <Popup trigger={<button className="editSubscriptionButton accountPageButton">Custom</button>} modal nested lockScroll >
            {close => (
                <div className="subscriptionPaymentPopup">
                    <h3 className="subscriptionTitle">Monthly Subscription</h3>
                    <label className="subscriptionPopupSubTitle" htmlFor="subAmount">Current Subscription Amount</label> <br />
                    <span className="subSpan">
                        <p className="currencySymbol">$</p>
                        <input type="number" step=".01" min="2.00" className="currSubscriptionAmountSetup" onChange={(e) => (tempSubscription = e.target.value)} defaultValue={newSubscriptionAmount / 100} />
                    </span>
                    <div className="subscriptionEditButtons">
                        <button className="confirmEdit" enabled={(tempSubscription > 4.99 && newSubscriptionAmount > 499) ? "true" : "false"} onClick={UpdateSubscriptionAmount}>Set Subscription</button>
                        <button className="cancelEdit" onClick={(event) => { event.preventDefault(); close(); }}>Cancel</button>
                    </div>
                </div>
            )}
        </Popup>
    );

    return (
        <div>
            {loading === true &&
                <LoadingPopup/>
            }
            {loading === false &&
            <div className="subscriptionMenu">
                {subUpdating === true && 
                    <div className="subUpdatingPopup">
                        <img className="loadingIcon" src="loading-placeholder.gif"/>
                    </div>
                }
                <div className="subscriptionStatus">
                    <h3 className="subStatusTitle">Subscription Status</h3>
                    <div>
                        <p className="subscriptionStatusYellowText">Status:</p>
                        <p className="subStatusResult" style={{ color: IsSubActive() ? "lightgreen" : "red" }}>{IsSubActive() ? ( IsSubTrial() ? "Trial" : "Active") : "Inactive"}</p>
                    </div>
                    {IsSubActive() && <div>
                        <p className="subscriptionStatusYellowText">Next Billing Date:</p>
                        <p className="subscriptionStatusYellowText">{GetNextBillDate()}</p>
                    </div>}
                </div>
                <hr />
                <div>
                    <div className="creditInfoForm">
                        <h3 className="paymentMethodTitle">Payment Method</h3>
                        <div className="paymentMethod">
                                <button className="paymentMenu" onClick={PaymentMenuOnClick} >
                                {selectedPayment === undefined && <p className="newPayment">Add New Payment Method</p>}
                                {selectedPayment !== undefined && <PaymentMethodListItem paymentMethod={selectedPayment} clickable={false} subscription={subscription} />}
                            </button>
                                <AddPaymentPopup className="accountPageButton" accountInfo={accountInfo} /> 
                                {selectedPayment === undefined && <span className="addNewPaymentSpan"> <button className="addNewPayment accountPageButton" onClick={OpenPaymentPopup} >Add</button> </span>}
                                {selectedPayment !== undefined && <span className="paymentModificationButtons"> <button className="setDefaultPayment accountPageButton" onClick={SetDefaultPayment} >Set Default</button> <button className="deletePayment accountPageButton" onClick={DeletePaymentMethod} >Delete</button> </span>}
                        </div>
                        {showPayments && <div className="paymentMethodsDropdown">
                            {paymentMethods.map((paymentMethod, index) => (
                                <div>
                                    <PaymentMethodListItem paymentMethod={paymentMethod} selected={PaymentSelected} clickable={true} breakAfter={true} subscription={subscription} />
                                    <hr />
                                </div>
                            ))}
                                <button className="newPaymentMenu" onClick={NewPaymentSelected} >+ Add New Payment Method</button>
                        </div>}
                    </div>
                    <hr />
                    <div className="subscriptionPaymentMenu">
                        <h3 className="subscriptionTitle">Monthly Subscription</h3>
                        <label className="subscriptionSubTitle" htmlFor="subAmount">Current Subscription Amount</label> <br />
                        <p className="currSubscriptionAmount">${parseFloat(newSubscriptionAmount / 100).toFixed(2)}</p>
                        <RecommendedSubButtons setSubValue1={RecommendedSub1} setSubValue2={RecommendedSub2} setSubValue3={RecommendedSub3} />
                        <p className="minPayment">Minimum Payment: $4.99</p>
                        <p className="taxPayment">Tax added on subscription change.</p>
                        </div>
                    <hr />
                    {IsSubActive() && <div>
                        <button className="deactivateSubscription accountPageButton" onClick={CancelSubscription} >Cancel Subscription</button>
                        {subscriptionAmount !== newSubscriptionAmount && <button className="updateSubscription accountPageButton" onClick={UpdateSubscription} >Update Subscription</button>}
                    </div>}
                    {IsSubActive() === false && newSubscriptionAmount !== 0 && selectedPayment !== undefined && <div>
                            <button className="deactivateSubscription accountPageButton" onClick={CheckAndMakeSubscription} >Start Subscription</button>
                    </div>}
                </div>
            </div>}
        </div>
    );
}

function PaymentMethodListItem({ paymentMethod, selected, clickable, breakAfter, subscription }) {

    function SelectedMethod() {
        if(clickable === true) selected(paymentMethod);
    }

    return (
        <div className="paymentMenuDropdownItem" onClick={SelectedMethod}>
            <div className="topLineCardInfo">
                <p className="paymentTypeBrand">{paymentMethod.card.brand}</p>
                {subscription !== null && subscription !== undefined && paymentMethod.id === subscription.default_payment_method && <p className="defaultPaymentMarker">DEFAULT</p> }
            </div>
            <div className="cardInfo">
                <p className="expiration">Exp: {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}</p>
                <p className="lastDigits">Last 4:{paymentMethod.card.last4}</p>
            </div>
        </div>
    );
}

function FetchAccountInfo(setAccountInfo) {

    var URL = process.env.REACT_APP_NODE_ADDRESS + "/GetAccountInfo";
    //console.log(Cookies.get("AccessToken"));
    GetAccessToken().then((token) => {
        //console.log(Cookies.get("AccessToken"));
        //console.log(token);


        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'token': token })
        };

        fetch(URL, reqOptions)
            .then((res) => res.json())
            .then((data) => { setAccountInfo(data.message); });
    });
}

function NewDeveloperPage({ accountInfo }) {
    const [reloadPage, setReloadPage] = useState(false);
    const devId = useRef("");
    const [stateCountry, setStateCountry] = useState();
    const [stateRegion, setStateRegion] = useState();
    const [message, setMessage] = useState();

    const studioName = useRef();
    const contactEmail = useRef();
    const streetAddress1 = useRef();
    const streetAddress2 = useRef();
    const city = useRef();
    const region = useRef();
    const country = useRef();
    const zipCode = useRef();
    const EIN = useRef();

    function SubmitNewDeveloperRequest(event) {
        event.preventDefault();

        var address = {
            'streetAddress1': streetAddress1.current,
            'streetAddress2': streetAddress2.current,
            'city': city.current,
            'region': stateRegion,
            'country': stateCountry,
            'zipCode': zipCode.current
        };

        var URL = process.env.REACT_APP_NODE_ADDRESS + "/MakeNewDeveloper";

        if (studioName.current === undefined || contactEmail.current === undefined || streetAddress1.current === undefined || city.current === undefined || stateCountry === undefined || stateRegion === undefined || zipCode.current === undefined) {
            setMessage("Missing requried information to register new developer.")
            return;
        }

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'studioName': studioName.current, 'email': contactEmail.current, 'userId': accountInfo.LudareID, 'ein': EIN.current, 'address': JSON.stringify(address) })
        };

        fetch(URL, reqOptions)
            .then((res) => res.json())
            .then((data) => {

                if (data.success === true) {
                    devId.current = data.message;
                    //setReloadPage(true);
                    window.location.assign("/Account?loadPage=Developer&loadDev=" + devId.current);
                }
                else {
                    setMessage(data.message);
                }

            });

    }

    function UpdateCountry(val) {
        country.current = val;
        setStateCountry(country.current);
    }

    function UpdateState(val) {
        region.current = val;
        setStateRegion(region.current);
    }

    return (
        <div>
            {message !== undefined && <p className="newDevMessage">{message}</p>}
            <h1 className="newDevtitle">Create New Developer</h1>
            <span className="requiredHeader"> <p className="requiredLabel">Required = </p> <p className="requiredMark">{'\u2731'}</p> </span>
            <form className="newDeveloperForm">
                <div>
                    <label htmlFor="studioName">Studio Name:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <input type="text" id="studioName" required onChange={(e) => studioName.current = e.target.value} />
                </div>
                <div>
                    <label htmlFor="studioEmail">Studio Contact Email:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <input type="email" id="studioEmail" required onChange={(e) => contactEmail.current = e.target.value} />
                </div>
                <div>
                    <label htmlFor="streetAddress1">Street Address 1:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <input type="text" id="streetAddress1" required onChange={(e) => streetAddress1.current = e.target.value} />
                </div>
                <div>
                    <label htmlFor="streetAddress2">Street Address 2:</label>
                    <input type="text" id="streetAddress2" onChange={(e) => streetAddress2.current = e.target.value} />
                </div>
                <div>
                    <label htmlFor="city">City:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <input type="text" id="city" required onChange={(e) => city.current = e.target.value} />
                </div>
                <div>
                    <label htmlFor="country">Country:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <CountryDropdown onChange={UpdateCountry} required value={stateCountry} style={{
                        float: 'right',
                        width: '50%',
                        height: 'fit-content',
                        margin: 'auto', 'margin-left': '0px'}} />
                </div>
                <div>
                    <label htmlFor="state">State:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <RegionDropdown country={stateCountry} required value={stateRegion} onChange={UpdateState} style={{
                        float: 'right',
                        width: '50%',
                        height: 'fit-content',
                        margin: 'auto', 'margin-left': '0px'
                    }} />
                </div>
                <div>
                    <label htmlFor="zipCode">Zip Code:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <input type="text" id="zipcode" required onChange={(e) => zipCode.current = e.target.value} />
                </div>
                <div>
                    <label htmlFor="ein">Tax Identification Number:</label>
                    <p className="requiredMark">{'\u2731'}</p>
                    <input type="text" id="ein" required onChange={(e) => EIN.current = e.target.value} />
                </div>
                <button className="makeNewDeveloper accountPageButton" onClick={SubmitNewDeveloperRequest}>Create</button>
                {reloadPage === true && <Navigate to={"/Account?loadPage=Developer&loadDev=" + devId.current}/>}
            </form>
        </div>
    );
}

function DeveloperPage({ devId, accountInfo, gameArray, devArray, currGame, setCurrGame }) {
    const [currPane, setCurrPane] = useState();
    const [gameDropDownVisible, setGameDropDownVisible] = useState(false);
    const [devInfo, setDevInfo] = useState();
    const [devAddress, setDevAddress] = useState();
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const [stateCountry, setStateCountry] = useState();
    const [stateRegion, setStateRegion] = useState();

    const studioName = useRef();
    const contactEmail = useRef();
    const streetAddress1 = useRef();
    const streetAddress2 = useRef();
    const city = useRef();
    const region = useRef();
    const country = useRef();
    const zipCode = useRef();
    const EIN = useRef();
    const addressUpdate = useRef(false);

    function ClickInfo() {
        setCurrPane("DevInfo");
    }

    useEffect(() => {

        var tmpDevInfo;

        if (devArray === undefined || devArray === null) return;

        for (var i = 0; i < devArray.length; i++) {
            if (IsValid(devId) === true && devId === devArray[i].DeveloperID) {
                tmpDevInfo = devArray[i];
                break;
            }
        }

        if (tmpDevInfo !== undefined) {
            var addr = JSON.parse(tmpDevInfo.Address);
            setDevAddress(addr);
            setStateCountry(addr.country);
            setStateRegion(addr.region);
        }

        setDevInfo(tmpDevInfo)
    }, [devArray, devId]);

    useEffect(() => {

        for (var i = 0; i < devArray.length; i++) {
            if (devId === devArray[i].DeveloperID) {
                setDevInfo(devArray[i]);
                if (IsValid(devArray[i].Address)) {
                    setDevAddress(JSON.parse(devArray[i].Address));
                }
                break;
            }
        }

    }, [devId]);

    function StartEdit() {
        addressUpdate.current = false;
        setEditing(true);
    }

    function EndEdit() {
        setEditing(false);
    }

    function EndEditUpdate(event) {
        event.preventDefault();
        setLoading(true);

        var address = null;

        if (addressUpdate.current === true) {
            address = {
                'streetAddress1': devAddress.streetAddress1,
                'streetAddress2': devAddress.streetAddress2,
                'city': devAddress.city,
                'region': stateRegion,
                'country': stateCountry,
                'zipCode': devAddress.zipCode
            };
            if (streetAddress1.current !== undefined) {
                address['streetAddress1'] = streetAddress1.current;
            }
            if (streetAddress2.current !== undefined) {
                address['streetAddress2'] = streetAddress2.current;
            }
            if (city.current !== undefined) {
                address['city'] = city.current;
            }
            if (zipCode !== undefined) {
                address['zipCode'] = zipCode.current;
            }
        }

        var URL = process.env.REACT_APP_NODE_ADDRESS + "/UpdateDeveloper";

        var updateParams = { 'devId': devId };

        if (studioName.current !== null) {
            updateParams['studioName'] = studioName.current;
        }

        if (contactEmail.current !== null) {
            updateParams['email'] = contactEmail.current;
        }

        if (address !== null) {
            updateParams['address'] = JSON.stringify(address);
        }

        if (EIN.current !== null) {
            updateParams['ein'] = EIN.current;
        }

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(updateParams)
        };

        fetch(URL, reqOptions)
            .then((res) => res.json())
            .then((data) => {

                if (data.success === true) {
                    var devURL = process.env.REACT_APP_NODE_ADDRESS + "/GetDeveloperInfo";

                    const devReqOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ 'developerId': devId })
                    };

                    fetch(devURL, devReqOptions)
                        .then((res) => res.json())
                        .then((data) => {

                            if (data.success === true) {
                                setDevInfo(data.message)

                                for (var i = 0; i < devArray.length; i++) {

                                    if (devId !== undefined && devId.toString() === devArray[i].DeveloperID.toString()) {
                                        devArray[i] = data.message;
                                        break;
                                    }
                                }
                            }
                            var addr = JSON.parse(data.message.Address);
                            setDevAddress(addr);
                            setStateCountry(addr.country);
                            setStateRegion(addr.region);
                            setLoading(false);
                            setEditing(false);
                        });
                }
                else {

                    setLoading(false);
                    setEditing(false);
                }

            });
    }

    function UpdateCountry(val) {
        addressUpdate.current = true;
        country.current = val;
        setStateCountry(country.current);
    }

    function UpdateState(val) {
        addressUpdate.current = true;
        region.current = val;
        setStateRegion(region.current);
    }

    return (
        <div>
            {loading === true &&
                <LoadingPopup />
            }
            <div className="devPagePane">
                {devInfo !== undefined && <h1 className="developerTitle">{devInfo.DevName}</h1>}
            
                {devInfo !== undefined && devInfo.Status === 1 && <div>
                    <p className="devPending">Developer approval pending.</p>
                </div>}
                {devInfo !== undefined && devInfo.Status === 2 && <div>
                    <p className="devDenied">Developer request needs changes.</p>
                </div>}
                {devInfo !== undefined && devInfo.Status === 3 && <div>
                    <p className="devDenied">Developer request denied.</p>
                </div>}
                {devInfo !== undefined && devInfo.Status === 4 && editing !== true && <div>
                    <div className="devPageHeader">
                        <div className="gameHoverDiv" onMouseEnter={(e) => { setGameDropDownVisible(true) }} onMouseLeave={(e) => { setGameDropDownVisible(false) }}>
                            <button className="gameDropDownButton" style={{ 'background-color': (currPane !== "DevInfo") ? "#ffcf00" : "lightgray" }}><GameTableEntry gameInfo={currGame} setCurrGame={setCurrGame} setCurrPane={setCurrPane} /></button>
                            <div className="gameDropDownDiv">
                                {gameDropDownVisible === true && <div className="gameDropDownList">
                                    {gameArray !== undefined && gameArray.length > 0 && gameArray.map((gameInfo, index) => {
                                        return <div className="gameTableEntryParent"><GameTableEntry gameInfo={gameInfo} setCurrGame={setCurrGame} setCurrPane={setCurrPane} /><hr /></div>
                                    })}
                                    <GameTableEntry setCurrGame={setCurrGame} setCurrPane={setCurrPane} />
                                </div>}
                            </div>
                        </div>
                        <button className="developerInfoButton" onClick={ClickInfo} style={{ 'background-color': (currPane === "DevInfo") ? "#ffcf00" : "lightgray" }}>Developer Info</button>
                    </div>
                    {currPane === "DevInfo" && devInfo !== undefined &&
                        <div className="devInfoPane">

                            <div>
                                <p>Address:</p>
                                <p>{devAddress.streetAddress1 + " " + devAddress.streetAddress2 + " " + devAddress.city + " " + devAddress.state + ", " + devAddress.country + " " + devAddress.zipCode}</p>
                            </div>
                            <div>
                                <p>Email:</p>
                                <p>{devInfo.ContactEmail}</p>
                            </div>
                            <button className="developerEditButton accountPageButton" onClick={StartEdit}>Edit Information</button>
                        </div>
                    }
                    {(currPane === undefined || currPane === "GameInfo") &&
                        <div>
                            {currGame !== undefined &&
                                <GamePage gameId={currGame} />
                            }
                            {currGame === undefined &&
                                <NewGamePage developerId={devId} />
                            }
                        </div>
                    }
                </div>}
                {devInfo !== undefined && devInfo.Status === 4 && editing === true && <div>
                    <form className="developerInfoForm">
                        <div>
                            <label htmlFor="studioName">Studio Name:</label>
                            <input type="text" id="studioName" required onChange={(e) => studioName.current = e.target.value} defaultValue={devInfo.DevName} />
                        </div>
                        <div>
                            <label htmlFor="studioEmail">Studio Contact Email:</label>
                            <input type="email" id="studioEmail" required onChange={(e) => contactEmail.current = e.target.value} defaultValue={devInfo.ContactEmail} />
                        </div>
                        <div>
                            <label htmlFor="streetAddress1">Street Address 1:</label>
                            <input type="text" id="streetAddress1" required onChange={(e) => { streetAddress1.current = e.target.value; addressUpdate.current = true; } } defaultValue={devAddress.streetAddress1} />
                        </div>
                        <div>
                            <label htmlFor="streetAddress2">Street Address 2:</label>
                            <input type="text" id="streetAddress2" onChange={(e) => { streetAddress2.current = e.target.value; addressUpdate.current = true; }} defaultValue={devAddress.streetAddress2} />
                        </div>
                        <div>
                            <label htmlFor="city">City:</label>
                            <input type="text" id="city" required onChange={(e) => { city.current = e.target.value; addressUpdate.current = true; } } defaultValue={devAddress.city} />
                        </div>
                        <div>
                            <label htmlFor="country">Country:</label>
                            <CountryDropdown onChange={UpdateCountry} required value={stateCountry} style={{
                                float: 'right',
                                width: '50%',
                                height: 'fit-content',
                                margin: 'auto', 'margin-left': '0px'
                            }} />
                        </div>
                        <div>
                            <label htmlFor="state">State:</label>
                            <RegionDropdown country={stateCountry} required value={stateRegion} onChange={UpdateState} style={{
                                float: 'right',
                                width: '50%',
                                height: 'fit-content',
                                margin: 'auto', 'margin-left': '0px'
                            }} />
                        </div>
                        <div>
                            <label htmlFor="zipCode">Zip Code:</label>
                            <input type="text" id="zipcode" required onChange={(e) => { zipCode.current = e.target.value; addressUpdate.current = true; } } defaultValue={devAddress.zipCode} />
                        </div>
                        <div>
                            <label htmlFor="ein">Tax Identification Number:</label>
                            <input type="text" id="ein" required onChange={(e) => EIN.current = e.target.value} defaultValue={devInfo.TaxID} />
                        </div>
                        <button className="accountPageButton" onClick={EndEditUpdate}>Update</button>
                        <button className="accountPageButton" onClick={EndEdit}>Cancel</button>
                    </form>
                </div>}
            </div>
        </div>
    );
}

//{reloadPage === true && <Navigate to={"/Account?loadPage=Developer&loadDev=" + devId.current} />}

function GameTableEntry({ gameInfo, setCurrGame, setCurrPane }) {
    const [gameTable, setGameTable] = useState(null);

    useEffect(() => {
        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameInfo })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/GetGameInfo', reqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    setGameTable(data.message);
                }
            });
    }, [gameInfo]);

    function GameClick() {
        setCurrGame(gameInfo);
        setCurrPane("GameInfo");
    }

    return (
        <div className="gameTableEntry">
            {(IsValid(gameTable) === true || IsValid(gameInfo) === false) &&
                <p onClick={GameClick}>{(IsValid(gameInfo) === false) ? "Make New Game" : gameTable.Name}</p>
            }
        </div>
    );
}

function GamePage({ gameId }) {
    const [gameInfo, setGameInfo] = useState();
    const [gameData, setGameData] = useState();
    const [upload, setUpload] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [buildUploadProgress, setBuildUploadProgress] = useState(null);
    const backgroundImage = useRef(null);
    const keyImage = useRef(null);
    const buildFile = useRef(null);
    const [tags, setTags] = useState([]);
    const [gameTags, setGameTags] = useState([]);

    useEffect(() => {
        const req = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: ""
        }

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/GetTagInfo', req)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    data.message.sort((a, b) => {
                        if (a.TagName < b.TagName) {
                            return -1;
                        }
                        if (a.TagName > b.TagName) {
                            return 1;
                        }
                        return 0;
                    });
                    setTags(data.message);
                }
            });
    }, [])

    useEffect(() => {
        GetGameInfo();

        var eventURL = process.env.REACT_APP_NODE_ADDRESS + "/GetGameEvents";

        const eventReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameId })
        };

        fetch(eventURL, eventReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    setGameData(data.message);
                }
            });

    }, [gameId]);

    useEffect(() => {
        if (refresh === true) setRefresh(false);
    }, [refresh])

    useEffect(() => {
        if (IsValid(gameInfo)) {
            var tmpGameInfo = gameInfo;
            tmpGameInfo.TagId = gameTags;
            setGameInfo(tmpGameInfo);
        }
    }, [gameTags])

    function GetGameInfo() {
        var URL = process.env.REACT_APP_NODE_ADDRESS + "/GetGameInfo";

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameId })
        };

        fetch(URL, reqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    if (IsValid(data.message.PublicBuildLinks) === true ) {
                        data.message.PublicBuildLinks = JSON.parse(data.message.PublicBuildLinks);
                    }

                    if (IsValid(data.message.PrivateBuildLinks) === true) {
                        data.message.PrivateBuildLinks = JSON.parse(data.message.PrivateBuildLinks);
                    }
                    setGameInfo(data.message);
                    setGameTags(data.message.TagId);

                    //console.log(data.message);
                }
            });
    }

    function UploadImage(gameId, type, fileContents) {
        setUpload(false);
        const name = `games/${gameId}/${type}.png`

        const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
        const REGION = process.env.REACT_APP_S3_REGION;

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
        })

        const params = {
            Bucket: S3_BUCKET,
            Key: name,
            Body: fileContents,
        }

        const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        s3.putObject(params)
            .on("httpUploadProgress", (evt) => {
                console.log("Updating " + parseInt((evt.loaded * 100) / evt.total) + "%");
            }).promise().then((err, data) => {
                console.log("File Uploaded");
                setUpload(true);
                var tmpGameInfo = gameInfo;
                tmpGameInfo.BackgroundUrl = gameInfo.BackgroundUrl;
                tmpGameInfo.PhotoUrl = gameInfo.PhotoUrl;
                setGameInfo(tmpGameInfo);
                setRefresh(true);
            });
        
    }

    function BuildUpload() {
        setUpload(false);
        var fileName = document.getElementById('uploadBuild').files[0].name;

        const name = `games/${gameInfo.ID}/${fileName}`;

        const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
        const REGION = process.env.REACT_APP_S3_REGION;

        const params = {
            Bucket: S3_BUCKET,
            Key: name,
            Body: document.getElementById('uploadBuild').files[0],
        }

        const s3 = new S3Client({
            region: REGION,
            credentials: {
                accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
                secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
            },
            requestHandler: new XhrHttpHandler({})
        });

        const upload = new Upload({
            client: s3,
            params: params
        });

        upload.on("httpUploadProgress", (progressEvent) => {
            setBuildUploadProgress({ 'loaded': progressEvent.loaded, 'total': progressEvent.total });
            console.log("Updating " + parseInt((progressEvent.loaded * 100) / progressEvent.total) + "%");
        })

        upload.done().then((err, data) => {
            console.log("File Uploaded");
            setUpload(true);

            UpdateBuildLinks(name);
        });
    }

    function UpdateBuildLinks(newLink) {
        var eventURL = process.env.REACT_APP_NODE_ADDRESS + "/GameBuildUploaded";

        const eventReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameId, 'buildLink': newLink })
        };

        fetch(eventURL, eventReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    var tempGameInfo = gameInfo;
                    tempGameInfo.PrivateBuildLinks = JSON.parse(data.message);
                    setGameInfo(tempGameInfo);
                    console.log(tempGameInfo);
                    setRefresh(true);
                }
            });
    }

    function CopySecret(event) {
        event.preventDefault();

        navigator.clipboard.writeText(gameInfo.Secret);

        alert("Secret Copied");
    }

    function UploadKeyArtPictureLink(gameId) {
        const keyArt = `games/${gameId}/keyArt.png`
        const background = `games/${gameId}/background.png`
        const req = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                gameId: gameId,
                secretKey: "J*oKUE8TytLJRaE298%-R3VHHnk#ybmtN-x9aA#HBV$hKwC1Q%PzEeHLp%a-(%9EhHJiFfBza*yQ4U-LZLCy8y_prT*YzEIIa12)",
                photoUrl: keyArt
            })
        }

        try {
            fetch(process.env.REACT_APP_NODE_ADDRESS + '/UpdateGame', req)
                .then((res) => res.json())
                .then((data) => {
                    if (data.success) {
                        setRefresh(true);
                        //return data;
                    } else {
                        throw new Error(data.message || 'Failed to update game info');
                    }
                });

            
        } catch (error) {
            console.error('Error updating game info:', error);
            return null;
        }
    }

    function UploadBackgroundPictureLink(gameId) {
        const keyArt = `games/${gameId}/keyArt.png`
        const background = `games/${gameId}/background.png`
        const req = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                gameId: gameId,
                secretKey: "J*oKUE8TytLJRaE298%-R3VHHnk#ybmtN-x9aA#HBV$hKwC1Q%PzEeHLp%a-(%9EhHJiFfBza*yQ4U-LZLCy8y_prT*YzEIIa12)",
                backgroundUrl: background
            })
        }

        try {
            fetch(process.env.REACT_APP_NODE_ADDRESS + '/UpdateGame', req)
                .then((res) => res.json())
                .then((data) => {
                    if (data.success) {
                        return data;
                    } else {
                        throw new Error(data.message || 'Failed to update game info');
                    }
                });


        } catch (error) {
            console.error('Error updating game info:', error);
            return null;
        }
    }

    function UploadKeyArt() {
        const gameId = gameInfo.ID;
        const keyArt = document.getElementById('uploadKeyArt').files[0];

        UploadImage(gameId, "keyArt", keyArt);
        UploadKeyArtPictureLink(gameId);
    }

    function UploadBackgroundArt() {
        const gameId = gameInfo.ID;
        const background = document.getElementById('uploadBackground').files[0];

        UploadImage(gameId, "background", background);
        UploadBackgroundPictureLink(gameId);
    }

    function ToggleVibility() {
        var eventURL = process.env.REACT_APP_NODE_ADDRESS + "/SetGameVisibility";

        const eventReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameId, 'visibility': !gameInfo.Visibility })
        };

        fetch(eventURL, eventReqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    var tempGameInfo = gameInfo;
                    tempGameInfo.Visibility = !gameInfo.Visibility;
                    setGameInfo(tempGameInfo);
                    setRefresh(true);
                }
            });
    }

    function UpdateGameInfo() {
        var URL = process.env.REACT_APP_NODE_ADDRESS + "/UpdateGame";

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameName': gameInfo.Name, 'gameId': gameInfo.ID, 'description': gameInfo.Description, 'tagId': gameTags, 'devNameOverride': gameInfo.DeveloperNameOverride, 'publisherName': gameInfo.PublisherName, 'websiteLink': gameInfo.WebsiteLink, 'steamLink': gameInfo.SteamLink })
        };

        fetch(URL, reqOptions)
            .then((res) => res.json())
            .then((data) => {

            });
    }

    return (
        <div className="gameInfoPane">
            {IsValid(gameInfo) === true && refresh === false && gameInfo.Status === 4 &&
                <div className="gameInfoForm">
                    <div className="gamePropDiv">
                        <p className="gameVisiblityLabel">Current Visibility:</p><p className="gameVisiblityStatus" style={{ color: gameInfo.Visibility ? "lightgreen" : "red" }}>{gameInfo.Visibility ? "Visible" : "Not Visible"}</p><button className="visiblityToggleButton" onClick={ToggleVibility}>{gameInfo.Visibility ? "Set Not Visible" : "Set Visible"}</button>
                    </div> <br />

                    <div className="gamePropDiv">
                        <label htmlFor="gameId">Game ID:</label> <input className="gameInputField" id="gameId" readOnly value={gameInfo.ID} />
                    </div> <br />
                    <div className="gamePropDiv">
                        <label htmlFor="gameSecret">Game Secret:</label> <input className="gameInputField" type="password" id="gameSecret" readOnly value={gameInfo.Secret} />
                    </div>
                    <button className="copySecretButton accountPageButton" onClick={CopySecret} >Copy Secret</button>
                    <br />
                    <br />

                    <div className="gamePropDiv">
                        <label htmlFor="gameName">Game Name:</label><input className="gameInputField" id="gameName" onChange={ (e) => gameInfo.Name = e.target.value } defaultValue={gameInfo.Name} />
                    </div> <br />
                    <div className="gamePropDiv">
                        <label htmlFor="gameDesc">Game Description:</label> <textarea className="gameDescField" id="gameSecret" onChange={(e) => gameInfo.Description = e.target.value} defaultValue={gameInfo.Description} />
                    </div> <br />
                    <div className="gamePropDiv">
                        <label htmlFor="gameDesc">Developer Name Override:</label> <input className="gameInputField" id="devOverride" onChange={(e) => gameInfo.DeveloperNameOverride = e.target.value} defaultValue={gameInfo.DeveloperNameOverride} />
                    </div> <br />
                    <div className="gamePropDiv">
                        <label htmlFor="gameDesc">Publisher Name:</label> <input className="gameInputField" id="publisher" onChange={(e) => gameInfo.PublisherName = e.target.value} defaultValue={gameInfo.PublisherName} />
                    </div> <br />
                    <div className="gamePropDiv">
                        <label htmlFor="gameDesc">Steam Link:</label> <input className="gameInputField" id="steamLink" onChange={(e) => gameInfo.SteamLink = e.target.value} defaultValue={gameInfo.SteamLink} />
                    </div> <br />
                    <div className="gamePropDiv">
                        <label htmlFor="gameDesc">Website Link:</label> <input className="gameInputField" id="websiteLink" onChange={(e) => gameInfo.WebsiteLink = e.target.value} defaultValue={gameInfo.WebsiteLink} />
                    </div> <br />
                    <div className="gamePropDiv">
                        <label htmlFor="gameTags">Game Tags:</label>
                        <div className="gameTagsField">
                            {tags.map((tag, index) => {
                                return <GameTagEntry tagName={tag.TagName} tagId={tag.id} currGameTags={gameTags} setCurrGameTags={setGameTags} />
                            }) }
                        </div>
                    </div> <br />
                    <button className="gameUpdateButton accountPageButton" onClick={UpdateGameInfo} >Update Game</button>
                    <br />
                    <p className="gameCardPreviewLabel">Library Listing Preview:</p>
                    <div className="gameCardPreview">
                        <GameCard gameId={gameId} gameInfo={gameInfo} />
                    </div>
                    <br/>

                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div className="gameUploadFieldDiv">
                            <label htmlFor="uploadBackground">Upload Background Here:</label>
                            <input type="file" id="uploadBackground" ref={backgroundImage} onChange={UploadBackgroundArt} style={{ display: 'none' }} />
                            <div className="backgroundUpload" onClick={(e) => { e.preventDefault(); backgroundImage.current.click(); }} >
                                <img className="uploadImageShow" src={process.env.REACT_APP_S3_BASE_URL + gameInfo.BackgroundUrl} alt="Current Background" />
                                <p className="uploadText">Click to select file to upload.</p>
                            </div>
                        </div>

                        <div className="gameUploadFieldDiv">
                            <label htmlFor="uploadKeyArt">Upload Key Art Here:</label>
                            <input type="file" id="uploadKeyArt" ref={keyImage} onChange={UploadKeyArt} style={{ display: 'none' }} />
                            <div className="backgroundUpload" onClick={(e) => { e.preventDefault(); keyImage.current.click(); }} >
                                <img className="uploadImageShow" src={process.env.REACT_APP_S3_BASE_URL + gameInfo.PhotoUrl} alt="Current Key Art" />
                                <p className="uploadText">Click to select file to upload.</p>
                            </div>
                        </div>

                        <div className="gameUploadFieldDiv">
                            <label htmlFor="uploadBuild">Upload Game Build Here:</label>
                            <input type="file" id="uploadBuild" ref={buildFile} onChange={BuildUpload} style={{ display: 'none' }} />
                            <div className="backgroundUpload" onClick={(e) => { e.preventDefault(); buildFile.current.click(); }} >
                                <p className="buildFileSelectorName">{IsValid(document.getElementById('uploadBuild')) && IsValid(document.getElementById('uploadBuild').files[0]) ? document.getElementById('uploadBuild').files[0].name : "Upload Build"}</p> 
                                <p className="uploadText">Click to select file to upload.</p>
                                {buildUploadProgress !== null &&
                                    <span><br /> <progress id="build" value={(buildUploadProgress.loaded / buildUploadProgress.total) * 100} max="100" /></span>
                                }
                            </div>
                        </div> 
                        <div className="buildFieldsDiv">
                            <div className="buildLabelsDiv">
                                <p className="buildNameLabel">File Name:</p>
                                <p className="buildPublicLabel">Is Public:</p>
                                <p className="buildWindowsLabel">Default Windows Build:</p>
                                <p className="buildMacLabel">Default Mac Build:</p>
                                <p className="buildLinuxLabel">Default Linux Build:</p>
                                <p className="buildDeleteLabel">Delete Build:</p>
                                <div className="buildLabelSpacer"/>
                            </div>
                            <div className="buildEntriesDiv">
                                {IsValid(gameInfo.PrivateBuildLinks) === true &&
                                    gameInfo.PrivateBuildLinks.map((link, index) => {
                                        return <GameBuildEntry linkInfo={link} gameInfo={gameInfo} setRefresh={setRefresh} updateBuildLinks={GetGameInfo} />
                                    }) 
                                }
                            </div>
                        </div>
                    </div>

                    {gameData !== undefined && gameData.length > 0 && <div> 
                        <h3 className="gameEventHeader">Recent Events</h3>
                        <span className="gameEventEntry"> <p>UserId</p> <p>Timestamp</p> <p>Event</p> </span>
                        {gameData.map((event, index) => {
                            return <span className="gameEventEntry"> <p>{event.UserID}</p> <p>{event.Timestamp.slice(0, 19).replace('T', ' ')}</p> <p>{event.Event}</p> </span>
                        }) }
                    </div>}
                </div>
            }
            {gameInfo !== undefined && gameInfo !== null && gameInfo.Status === 1 &&
                <div>
                    <p className="gameUnderReview">Your game is under review.</p>
                </div>
            }
            {gameInfo !== undefined && gameInfo !== null && gameInfo.Status === 2 &&
                <div>
                    <p className="gameChangesNeeded">Please edit your game to be approved.</p>
                </div>
            }
            {gameInfo !== undefined && gameInfo !== null && gameInfo.Status === 3 &&
                <div>
                    <p className="gameDenied">Your game application is deined.</p>
                </div>
            }
        </div>
    );
}

function NewGamePage({developerId }) {
    const gameName = useRef();
    const [message, setMessage] = useState();
    const description = useRef();
    const buildLink = useRef();
    const imageLink = useRef();
    const [loading, setLoading] = useState(false);

    function SubmitNewGame(event) {
        event.preventDefault();

        var URL = process.env.REACT_APP_NODE_ADDRESS + "/MakeNewGame";

        if (IsValid(gameName.current) === false ||
            IsValid(description.current) === false ||
            IsValid(buildLink.current) === false ||
            IsValid(imageLink.current) === false) {

            console.log("Missing Param");
            setMessage("Missing at least one required parameter");
            return;
        }

        setLoading(true);

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameName': gameName.current, 'developerId': developerId, 'description': description.current, 'imageLink': imageLink.current, 'buildLink': buildLink.current })
        };

        fetch(URL, reqOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    window.location.assign("/Account?loadPage=Developer&loadDev=" + developerId + "&loadGame=" + data.message);
                }
                else {
                    setMessage(data.message);
                }
                setLoading(false);
            });
    }

    return (
        <div className="newGameDiv">
            {loading === true && <LoadingPopup /> }
            {message !== undefined && <p className="newGameMessage">{message}</p>}
            <form className="newGameForm">
                <label htmlFor="gameName">Game Name: </label>
                <input type="text" id="gameName" onChange={(e) => { gameName.current = e.target.value }} /> <br />
                <label htmlFor="description">Description: </label>
                <input type="text" id="description" onChange={(e) => { description.current = e.target.value }} /> <br />
                <label htmlFor="buildLink">Build Link: </label>
                <input type="text" id="buildLink" onChange={(e) => { buildLink.current = e.target.value }} /> <br />
                <label htmlFor="imageLink">Image Link: </label>
                <input type="text" id="imageLink" onChange={(e) => { imageLink.current = e.target.value }} /> <br />
                <button className="newGameSubmit accountPageButton" disabled={loading !== false} onClick={SubmitNewGame}>Make New Game</button>
            </form>
        </div>
    );
}

function DeveloperListItem({ developerInfo, setDeveloperId, setPage, setDevId, setCurrGame, currDevId, setCurrDevId, currPage }) {
    const [developerName, setDeveloperName] = useState("");

    useEffect(() => {

        if (developerInfo === null || developerInfo === undefined) {
            setDeveloperName("+ Create New Developer");
        }
        else {
            setDeveloperName(developerInfo.DevName);
        }
    }, [])

    function SetDeveloperPage() {

        setCurrGame(undefined);

        if (developerInfo === null || developerInfo === undefined) {
            setPage("NewDeveloper");
            setCurrDevId(undefined);
        }
        else {
            setDevId(developerInfo.DeveloperID);

            setPage("Developer");
        }
    }

    return (
        <div>
            {developerName !== undefined && <button style={{ background: (((developerInfo !== undefined && currDevId === developerInfo.DeveloperID) || (developerInfo === undefined && currPage === "NewDeveloper")) ? 'rgba(100, 100, 100, 1)' : 'rgba(0, 0, 0, 0)') }} className="developerMenuButton" onClick={SetDeveloperPage}>{developerName}</button>}
        </div>
    );
}

function GameBuildEntry({ linkInfo, gameInfo, setRefresh, updateBuildLinks }) {
    const [waitForResponse, setWaitForResponse] = useState(false);

    function OnChangePublic(e) {

        setWaitForResponse(true);

        //console.log(e.target.checked);

        if (e.target.checked === true) {
            const signUpReqOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'gameId': gameInfo.ID, 'buildLink': linkInfo })
            };

            fetch(process.env.REACT_APP_NODE_ADDRESS + '/SetBuildPublic', signUpReqOptions)
                .then((res) => res.json())
                .then((data) => {
                    setWaitForResponse(false);
                    //console.log(data);
                    if (data.success === true) {
                        updateBuildLinks();
                        setRefresh(true);
                    }
                });
        }
        else {
            const signUpReqOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'gameId': gameInfo.ID, 'buildLink': linkInfo })
            };

            fetch(process.env.REACT_APP_NODE_ADDRESS + '/RemoveBuildPublic', signUpReqOptions)
                .then((res) => res.json())
                .then((data) => {
                    setWaitForResponse(false);
                    //console.log(data);
                    if (data.success === true) {
                        updateBuildLinks();
                        setRefresh(true);
                    }
                });
        }
    }

    function SetWindowsBuild(e) {
        setWaitForResponse(true);

        var LinkToSend = linkInfo;

        if (e.target.checked === false) {
            LinkToSend = null;
        }

        const signUpReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameInfo.ID, 'buildLink': LinkToSend })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/SetWindowsBuild', signUpReqOptions)
            .then((res) => res.json())
            .then((data) => {
                setWaitForResponse(false);
                if (data.success === true) {
                    updateBuildLinks();
                    setRefresh(true);
                }
            });
    }

    function SetMacBuild(e) {
        setWaitForResponse(true);

        var LinkToSend = linkInfo;

        if (e.target.checked === false) {
            LinkToSend = null;
        }

        const signUpReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameInfo.ID, 'buildLink': LinkToSend })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/SetMacBuild', signUpReqOptions)
            .then((res) => res.json())
            .then((data) => {
                setWaitForResponse(false);
                if (data.success === true) {
                    updateBuildLinks();
                    setRefresh(true);
                }
            });
    }

    function SetLinuxBuild(e) {
        setWaitForResponse(true);

        var LinkToSend = linkInfo;

        if (e.target.checked === false) {
            LinkToSend = null;
        }

        const signUpReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameInfo.ID, 'buildLink': LinkToSend })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/SetLinuxBuild', signUpReqOptions)
            .then((res) => res.json())
            .then((data) => {
                setWaitForResponse(false);
                if (data.success === true) {
                    updateBuildLinks();
                    setRefresh(true);
                }
            });
    }

    function DeleteBuild(e) {
        setWaitForResponse(true);

        const signUpReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'gameId': gameInfo.ID, 'buildLink': linkInfo })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/RemoveBuild', signUpReqOptions)
            .then((res) => res.json())
            .then((data) => {
                setWaitForResponse(false);
                if (data.success === true) {
                    updateBuildLinks();
                    setRefresh(true);
                }
            });
    }

    function CheckPublic() {
        return IsValid(gameInfo.PublicBuildLinks) && gameInfo.PublicBuildLinks.includes(linkInfo);
    }

    return (
        <div>
            {IsValid(linkInfo) === true && <div className="buildEntryDiv">
                <p className="buildName">{linkInfo.split('/').pop()}</p>
                <input className="buildPublicCheck" type="checkbox" checked={CheckPublic()} onChange={OnChangePublic} disabled={(waitForResponse !== false)} />
                <input className="buildWindowsCheck" type="checkbox" checked={gameInfo.WindowsBuild === linkInfo} onChange={SetWindowsBuild} disabled={(waitForResponse !== false)} />
                <input className="buildMacCheck" type="checkbox" checked={gameInfo.MacBuild === linkInfo} onChange={SetMacBuild} disabled={(waitForResponse !== false)} />
                <input className="buildLinuxCheck" type="checkbox" checked={gameInfo.LinuxBuild === linkInfo} onChange={SetLinuxBuild} disabled={(waitForResponse !== false)} />
                <button className="deleteBuildButton" onClick={DeleteBuild} disabled={(waitForResponse !== false)}>Delete</button>
            </div>}
        </div>
    );
}

function GameTagEntry({ tagName, tagId, currGameTags, setCurrGameTags }) {
    const [refresh, setRefesh] = useState(false);

    useEffect(() => {
        if (refresh === true) {
            setRefesh(false);
        }
    }, [refresh])

    function AddTag(e) {
        e.preventDefault();

        var tagArray = currGameTags !== null ? currGameTags.split(',') : [];

        if (e.target.checked === false) {
            if (tagArray.length === 1) {
                setCurrGameTags("");
                return;
            }
            tagArray = tagArray.filter((id) => id !== tagId.toString());
        }
        else {
            tagArray.push(tagId);
        }
        setCurrGameTags(tagArray.join(','));
        setRefesh(true);
    }

    function CheckIsEnabled() {
        if (IsValid(currGameTags) !== true) return false;

        var tagArray = currGameTags.split(',');

        for (var i = 0; i < tagArray.length; i++) {
            if (tagArray[i] === tagId.toString()) {
                return true;
            }
        }

        return false;
    }

    return (
        <div className="gameTagEntry">
            {refresh === false && <input className="gameTagCheck" type="checkbox" onChange={AddTag} checked={CheckIsEnabled()} disabled={IsValid(currGameTags) === true && currGameTags.split(',').length >= 4 && currGameTags.split(',').includes(tagId) === false} />}
            <p className="gameTagName">{tagName}</p>
        </div>
    );
}