import {
    Body,
    Container,
    Head,
    Heading,
    Html,
    Img,
    Tailwind,
    Text,
    Button,
} from "@react-email/components";
import * as React from "react";

interface OutreachProps {
    studioName: string;
    game: string;
    game1: string;
    game2: string;
}

const baseUrl = process.env.VERCEL_URL 
? `https://${process.env.VERCEL_URL}` : "";

//{ studioName, game, game1, game2 }: OutreachProps

const PathlessEmail = () => {
    const backgroundImageUrl = `https://www.devpowered.com/HOMEPAGE-background.png`;
    const logoUrl = `https://www.devpowered.com/LudareLogoTransparent.png`;
    const signupNowUrl = `https://www.devpowered.com/signup-now.png`;

    return (
        <Html>
            <Head>
                <link
                    href="https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap"
                    rel="stylesheet"
                />
            </Head>
            <Body>
                <Container style={main}>
                    <Container style={ContainerStyle } >
                        <div style={headerDiv }>
                            <Text style={headerText }>Hello from</Text>
                            <Img src={logoUrl} style={topLogo} />
                        </div>
                        <Text style={bodyText}>Hello [STUDIO NAME],</Text>
                        <Text style={bodyText}>
                            [INSERT INTRO PARAGRAPH]
                        </Text>
                        <Text style={bodyText}>
                            We are reaching out because we're launching a service to support games from indie developers like yourself.
                            The service is called Ludare and it helps studios generate revenue from engagement.
                            Players set aside money on a monthly basis that they distribute to developers on how often they engage with their games over that month.
                            Imagine it as a collective Patreon for indie developers.
                            It’s easy and fast to get started with Ludare:
                        </Text>
                        <div style={headerDiv}>
                            <div style={multiLineText}>
                                <Text style={subHeaderText}>Start Earning in</Text>
                                <Text style={colorText}>3 Easy Steps</Text>
                            </div>
                            <Img src={`https://www.devpowered.com/speed.png`} style={speedIcon}  />
                        </div>
                        <Img src={`https://www.devpowered.com/steps.png`} style={midImage} />
                        <div style={centerTextContainer}>
                            <Button href="https://www.devpowered.com/" style={yellowButton}>Learn More</Button>
                        </div>
                        <div style={centerTextContainer}>
                            <Text style={bodyText}>
                                We would love to talk to you more about the integration. You can schedule a 30-minute meeting with us here:
                            </Text>
                        </div>
                        <div style={centerTextContainer}>
                            <Button href="https://calendly.com/matt-3pod/30min" style={bookTimeButton}>Book a Time</Button>
                        </div>
                        <Text style={bodyText}>
                            Thank you, and we look forward to hearing from you!<br /> <br/>-[NAME]
                        </Text>
                    </Container>
                    <div style={signUpContainer}>
                        <div style={signUpTextContainer}>
                            <a href="https://www.devpowered.com/login?page=signUp" style={signUpText}>SIGN UP NOW!</a>
                        </div>
                        <Img style={signUpImg} src={signupNowUrl} />
                    </div>
                    <div style={footerDiv} >
                        <a style={footerLogoDiv} href="https://www.devpowered.com/" target="_blank" rel="noopener noreferrer">
                            <Img style={footerLogo} src={logoUrl}/>
                        </a>
                        <div style={socialsDiv}>
                            <a href="https://twitter.com/LudareOfficial" target="_blank" rel="noopener noreferrer">
                                <Img style={socialsIcon} src={`https://www.devpowered.com/twitter.png`} />
                            </a>
                            <a href="https://www.tiktok.com/@ludareofficial" target="_blank" rel="noopener noreferrer">
                                <Img style={socialsIcon} src={`https://www.devpowered.com/tik-tok.png`} />
                            </a>
                            <a href="https://www.instagram.com/ludareofficial/" target="_blank" rel="noopener noreferrer">
                                <Img style={socialsIcon} src={`https://www.devpowered.com/instagram.png`} />
                            </a>
                            <a href="mailto:contact@pathlessproductions.com" target="_blank" rel="noopener noreferrer">
                                <Img style={socialsIcon} src={`https://www.devpowered.com/email.png`} />
                            </a>
                        </div>
                        <Text style={footerText}>&copy; 2024 Pathless Productions, Inc.</Text>
                    </div>
                </Container>
            </Body>
        </Html>
    );
};

const ContainerStyle = {
    'min-height': 'fit-content',
    'max-width': '800px',
    'display': 'flex',
    'flex-direction': 'column',
    'width': '80%',
    margin: 'auto',
};

const mainContainer = {
    fontFamily: 'Play, sans-serif',
    width: '100%',
};

const main = {
    fontFamily: 'Play, sans-serif',
    width: '100%',
    'max-width': '800px',
    postion: 'relative',
    background: "repeat-y center/100% url(https://www.devpowered.com/HOMEPAGE-background.png), #30333A",
};

const headerText = {
    fontFamily: 'Play, sans-serif',
    'color': "#FFFFFF",
    'font-size': "60px",
    height: 'fit-content',
    margin: 'auto',
};

const bodyText = {
    fontFamily: 'Play, sans-serif',
    'color': "#FFFFFF",
    'font-size': "24px",
};

const colorText = {
    'color': '#ffcf00',
    fontWeight: 400,
    fontFamily: 'Play, sans-serif',
    'font-size': "36px",
    margin: 'auto',
    'margin-bottom': '5px',
    width: 'fit-content',
};

const subHeaderText = {
    fontFamily: 'Play, sans-serif',
    'color': "#FFFFFF",
    'font-size': "36px",
    height: 'fit-content',
    margin: 'auto',
    'margin-bottom': '5px',
    width: 'fit-content',
};

const topLogo = {
    width: '150px',
    height: 'auto',
};

const footerLogo = {
    width: '60px',
    height: 'fit-content',
    margin: 'auto',
    'margin-left': '0px',
    'margin-right': '0px',
};

const footerLogoDiv = {
    width: 'fit-content',
    height: 'fit-content',
    margin: 'auto',
    'margin-left': '0px',
    'margin-right': '0px',
}

const headerDiv = {
    width: 'fit-content',
    display: 'flex',
    'flex-direction': 'row',
    margin: 'auto',
};

const multiLineText = {
    width: 'fit-content',
    height: 'fit-content',
    display: 'flex',
    'flex-flow': 'row wrap',
    margin: 'auto',
};

const speedIcon = {
    width: '60px',
    height: 'auto',
    margin: 'auto',
}

const midImage = {
    width: '100%',
    height: 'auto',
}

const yellowButton = {
    color: "#000000",
    width: "200px",
    height: "60px",
    'border-radius': '30px',
    'font-size': "30px",
    backgroundColor: '#ffcf00',
    fontFamily: 'Play, sans-serif',
    margin: 'auto',
    'text-align': 'center',
    'justify-text': 'center',
    'line-height': '60px',
};

const bookTimeButton = {
    color: "#000000",
    width: "200px",
    height: "60px",
    'border-radius': '30px',
    'font-size': "30px",
    backgroundColor: '#ffffff',
    fontFamily: 'Play, sans-serif',
    margin: 'auto',
    'text-align': 'center',
    'justify-text': 'center',
    'border-style': 'solid',
    'border-color': "#000000",
    'line-height': '60px',
};

const centerTextContainer = {
    width: '100%',
    height: '50%',
    'display': 'flex',
    'flex-direction': 'column',
};

const signUpContainer = {
    width: '100%',
    height: 'auto',
    postion: 'relative',
};

const signUpTextContainer = {
    postion: 'absolute',
    width: '100%',
    height: '80px',
    display: 'flex',
    top: '8px',
    'flex-direction': 'row',
    margin: 'auto',
};

const signUpText = {
    width: 'fit-content',
    height: '80px',
    color: "#ffcf00",
    'font-size': "60px",
    margin: 'auto',
    'line-height': '80px',
};

const signUpImg = {
    width: '100%',
    height: 'auto',
};

const footerDiv = {
    width: '100%',
    height: '150px',
    display: 'flex',
    'flex-direction': 'row',
    backgroundColor: "#000000",
    'justify-content': 'space-between',
    'align-content': 'center',
};

const socialsDiv = {
    width: '40%',
    height: 'fit-content',
    display: 'flex',
    'flex-direction': 'row',
    margin: 'auto',
    'margin-left': '0px',
    'margin-right': '0px',
    'justify-content': 'space-between',
};

const socialsIcon = {
    width: '60px',
    height: 'auto',
};

const footerText = {
    fontFamily: 'Play, sans-serif',
    'color': "#ffffff",
    'font-size': "14px",
    height: 'fit-content',
    margin: 'auto',
    'margin-left': '0px',
    'margin-right': '0px',
};

export default PathlessEmail;
