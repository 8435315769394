// JavaScript source code
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

function DevOverride() {
    useEffect(() => {
        Cookies.set("DevOverride", true, { expires: 30 });
    }, []);

    return (
        <Navigate to="/" />
    );
}

export default DevOverride;