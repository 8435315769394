// JavaScript source code
import "./styles/CookiePolicy.css";

function CookiePolicy() {
    return (
        <div className="cookiePolicyContainer">
            <h1>Ludare Cookie Policy</h1>
            <p>A cookie is a piece of data set in your browser that allows us to maintain a session as you visit different pages on our website. By using Ludare, both first and third person cookies may be set when you access the site.</p>
            <p>This policy describes how Ludare and third parties we utilize use cookies and what data those cookies may collect.</p>
            <h2>Cookie Usage</h2>
            <p>We use cookies on Ludare to improve our service for you and to provide certain features of the website to use. Generally these cookies fall into two categories:</p>
            <p>Account Cookies: Cookies that are used to track your account information and keep you logged in as you navigate pages on the Ludare website. These cookies are:</p>
            <ul>
                <li><p>LudareAccessToken: Short term token used to associate your account with a login session.</p></li>
                <li><p>LudareRefreshToken: Long term token used to keep your account logged in between sessions.</p></li>
            </ul>
            <p>Analytics Cookies: Cookies used to track preferences and performance of the website to verify potential issues. These Cookies are:</p>
            <ul>
                <li><p>LudareCookiePolicy: Cookie used to track what cookies the website is allowed to use based on your selections.</p></li>
            </ul>
            <p>We also use third party technologies that may implement their own cookies, including:</p>
            <ul>
                <li><p>Stripe: </p><a href="https://stripe.com/gb/privacy">https://stripe.com/gb/privacy</a></li>
                <li><p>Google Analytics: </p><a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a></li>
            </ul>
            <h2>How do I restrict cookies?</h2>
            <p>You can limit which cookies third-party technologies can set in your internet browser. For information, refer to the help section of your browser.</p>
            <p>Cookies set in the browse have an expiration date assigned to them. They can also be explicity removed at any time from your browser and their associated data will also be removed. Keep in mind however that deleting these cookies may effect how your session functions.</p>
            <p>If you have any question, please contact us at <a gref="mailto:contact@pathlessproductions.com">contact@pathlessproductions.com</a>.</p>
        </div>
    );
}

export default CookiePolicy;
