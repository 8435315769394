import React from 'react';
import Cookies from 'js-cookie';
import './styles/App.css';
import LoginPage from './LoginPage';
import Header from './Header';
import { BrowserRouter as Router, Switch, Routes, Route, Link, useLocation, useSearchParams } from "react-router-dom";
import RegisterGameEvent from './RegisterGameEvent';
import HomePage from './HomePage';
import PlayerInfo from './PlayerInfo';
import AccountPage from './AccountPage';
import SignUpConfirmation from './SignUpConfirmation';
import PaymentSetup from './PaymentSetup';
import SetupSubscription from './SetupSubscription';
import GameSignUp from './GameSignUpForm';
import GoogleLinkReturn from './GoogleLinkReturn';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import StudiosPage from './StudiosPage';
import LibraryPage from './Library'
import ContactUs from './ContactUs';
import ExpressInterest from './ExpressInterest';
import PlayerInterest from './PlayerInterest';
import Footer from './Footer';
import PrivacyPolicy from './Privacy.js';
import PressKit from './PressKit.js';
import DevOverride from './DevOverride';
import EosReturn from './EosReturn';
import TOS from './TermsOfService';
import { GetAccessToken } from "./Utilities";
import ParentalApproval from "./ParentalApproval.js";
import ConfirmAccount from "./AccountConfirm";
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import CookieFooter from './CookieFooter';
import CookiePolicy from './CookiePolicy';
import Pathless from './Emails/pathless-email';
import OutreachEmail from './OutreachEmail';
import { PasswordReset } from "./Emails/PasswordReset";
import GameDatabaseEditor from './GameDatabaseEditor';
import StudioTools from './StudioConferenceTools';
import GameDownloads from './GameDownloads';

//import { render } from '@react-email/render';

function App() {
    const [authStatus, setAuthStatus] = React.useState(Cookies.get("LudareRefreshToken") !== undefined);
    

    /*React.useEffect(() => {
        console.log(render(AccountConfirmation()));
    }, []);*/

    //const initiateAuth
    /*Amplify.configure({
        Auth: {
            region: 'us-east-2',
            userPoolId: 'us-east-2_QrmSrjdiI',
            userPoolWebClientId: 'v2915k1lus6silablman63otr'
            
        }
    })*/

    return (
        <div className="pageContainer">
            <React.StrictMode>
                <Router>
                    <Header status={authStatus} setStatus={setAuthStatus} />
                    <div className="pageBody">
                        <Routes>
                            <Route path="/" element={
                                <div>
                                    <HomePage />
                                </div>
                            } />
                            <Route path="/players" element={
                                <div>
                                    <PlayerInfo />
                                </div>
                            } />
                            <Route path="/studios" element={
                                <div>
                                    <StudiosPage />
                                </div>
                            } />

                            <Route path="/library" element={
                                <div>
                                    <LibraryPage />
                                </div>
                            } />
                            <Route path="/contactUs" element={
                                <div>
                                    <ContactUs />
                                </div>
                            } />
                            <Route path="/login" element={
                                <div>
                                    <LoginPage status={authStatus} setStatus={setAuthStatus} />
                                </div>
                            } />
                            <Route path="/account" element={
                                <div>
                                    <AccountPage status={authStatus} />
                                </div>
                            } />
                            <Route path="/confirmation" element={
                                <div>
                                    <SignUpConfirmation />
                                </div>
                            } />
                            <Route path="/paymentSetup" element={
                                <div>
                                    <PaymentSetup  />
                                </div>
                            } />
                            <Route path="/subscription" element={
                                <div>
                                    <SetupSubscription />
                                </div>
                            } />
                            <Route path="/gameSignUp" element={
                                <div>
                                    <GameSignUp />
                                </div>
                            } />
                            <Route path="/GoogleReturn" element={
                                <div>
                                    <GoogleLinkReturn />
                                </div>
                            } />
                            <Route path="/expressInterest" element={
                                <div>
                                    <ExpressInterest />
                                </div>
                            } />
                            <Route path="/playerSignUp" element={
                                <div>
                                    <PlayerInterest />
                                </div>
                            } />
                            <Route path="/privacy" element={
                                <div>
                                    <PrivacyPolicy />
                                </div>
                            } />
                            <Route path="/pressKit" element={
                                <div>
                                    <PressKit />
                                </div>
                            } />
                            <Route path="/devOverride" element={
                                <div>
                                    <DevOverride />
                                </div>
                            } />
                            <Route path="/EosReturn" element={
                                <div>
                                    <EosReturn />
                                </div>
                            } />
                            <Route path="/TOS" element={
                                <div>
                                    <TOS />
                                </div>
                            } />
                            <Route path="/ParentalApproval" element={
                                <div>
                                    <ParentalApproval />
                                </div>
                            } />
                            <Route path="/ConfirmAccount" element={
                                <div>
                                    <ConfirmAccount />
                                </div>
                            } />
                            <Route path="/ForgotPassword" element={
                                <div>
                                    <ForgotPassword />
                                </div>
                            } />
                            <Route path="/ResetPassword" element={
                                <div>
                                    <ResetPassword />
                                </div>
                            } />
                            <Route path="/EmailCheck" element={
                                <div>
                                    <Pathless/>
                                </div>
                            } />
                            <Route path="/CookiePolicy" element={
                                <div>
                                    <CookiePolicy />
                                </div>
                            } />
                            <Route path="/OutreachEmail" element={
                                <div>
                                    <OutreachEmail/>
                                </div>
                            } />
                            <Route path="/AdminEditor" element={
                                <div>
                                    <GameDatabaseEditor />
                                </div>
                            } />
                            <Route path="/GameDownloads" element={
                                <div>
                                    <GameDownloads />
                                </div>
                            } />
                            <Route path="/StudioTools" element={
                                <div>
                                    <StudioTools />
                                </div>
                            } />
                        </Routes>
                    </div>
                </Router>
                <Footer />
                {Cookies.get("LudareCookiePolicy") === undefined && <CookieFooter />}
            </React.StrictMode>
        </div>
    );
}

export default App;
