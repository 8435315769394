// JavaScript source code
import './styles/ContactUs.css';
import './styles/ContactUs.css';
import React, { useState, useEffect, useRef } from "react";
import GameImageHeader from './GameImageHeader';
//import { Resend } from 'resend';

//const resend = new Resend('re_hi9Mwts4_CzAinFfKfMcmtEjQgz9oViqZ');

function ContactUs() {

    return (
        <div className="contactForm">
            <div className="titleLineContact">
                <GameImageHeader />
                <div className="topBodyContact">
                    <div className="infoContact">
                        <h2 className="taglineTopContact">Got any</h2>
                        <h2 className="taglineBottomContact">questions?</h2>
                        <p className="subTagLineContact">We'd love to hear from you! Whether you have questions, <br /> feedback, or need assistance, our team is here to help.</p>
                    </div>
                </div>
            </div>
            <ContactUsForm />
        </div>
    );
}

export function ContactUsForm() {
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();
    const [subject, setSubject] = useState();
    const [messageSent, setMessageSent] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'email': email, 'message': "<p>" + message + "</p>", 'subject': subject })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/SendContactEmail', reqOptions)
            .then((res) => res.json())
            .then((data) => {
                setMessageSent(data.success);
            });
    }

    return (
        <div className="contactForm">
            <h1 className="contactTitle">Reach out to us!</h1>
            {messageSent === false && <form id="contactUsForm" onSubmit={onSubmit}>
                <label htmlFor="email">Email* </label> <br />
                <input className="contactEmailInput" type="email" id="email" name="email" onChange={(e) => setEmail(e.target.value)} /> <br /> <br />
                <label htmlFor="subject">Subject* </label> <br />
                <input className="contactSubjectInput" type="text" id="subject" name="subject" onChange={(e) => setSubject(e.target.value)} /> <br /> <br />
                <label htmlFor="message">Message* </label> <br />
                <textarea className="contactContentInput" type="text" id="message" rows="10" cols="100" onChange={(e) => setMessage(e.target.value)} /> <br /> <br />
                <input className="contactSubmitButton" name="submit" type="submit" value="Submit" />
            </form>}
            {messageSent === true &&
                <h1 className="messageSentConfirmation">Your message has been sent!</h1>
            }
        </div>
    );
}

export default ContactUs;
