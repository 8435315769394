// JavaScript source code
import './styles/AccountMenu.css';
import { useLayoutEffect, useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';
import { GetAccessToken } from "./Utilities";

function AccountMenu({ status, setStatus, collapsed }) {
    const [size, setSize] = useState([0, 0]);
    const [hovered, setHovered] = useState(false);
    const [logout, setLogout] = useState(false);

    useLayoutEffect(() => {

        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
    }, []);

    function SignOut() {

        if (Cookies.get("LudareRefreshToken") === undefined) return;

        GetAccessToken().then((token) => { 

            const reqOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'RefreshToken': Cookies.get("LudareRefreshToken"), 'AccessToken': token })
            };

            fetch(process.env.REACT_APP_NODE_ADDRESS + '/Logout', reqOptions)
                .then((res) => res.json())
                .then((data) => {
                    Cookies.remove("LudareAccessToken");
                    Cookies.remove("LudareRefreshToken");
                    setStatus(false);
                });
        });
    };

    return (
        <div className="accountWrapper">
            {collapsed !== true &&
                <div className="accountMenuParent">

                    {!status && <div>
                        <Link className="signInMenu" to='/login'>Sign In!</Link>
                    </div>}
                    {status && <div
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                    >
                        <Link className="accountMenu" to='/account'>Account</Link>
                        {hovered && <ul className="accountDropdown">
                            {status === true && <li className="libraryItem"><a className="libraryAccess" href='/GameDownloads'>Downloads</a></li>}
                            <li className="menuBreak"><hr className="accountBreak" /></li>
                            <li className="signOut" ><a className="signOutExpanded" onClick={SignOut}>SignOut</a></li>
                        </ul>}
                    </div>}
                </div>
            }
            {collapsed === true &&
                <div className="accountMenuParent">

                    {!status && <div>
                        <Link className="signInMenuCollapsed" to='/login'>Sign Up/Login</Link>
                    </div>}
                    {status && <ul className="accountDropdown">
                        <li><Link className="accountMenuCollapsed" to='/account'>Account</Link></li>
                        <li><hr className="accountBreak" /></li>
                        {status === true && <li><a className="libraryAccess" href='/GameDownloads'>Downloads</a></li>}
                        <li><hr className="accountBreak" /></li>
                        <li className="signOutCollapsed" ><a className="signOutExpanded" onClick={SignOut}>SignOut</a></li>
                    </ul>}
                </div>
            }
        </div>
    );
}

export default AccountMenu;
