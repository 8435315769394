// JavaScript source code
import './styles/GameSignUpForm.css';
import React, { useState, useEffect } from "react";

function GameSignUp() {
    const [submitted, setSubmitted] = useState(false);
    const [studioName, setStudioName] = useState("");
    const [gameName, setGameName] = useState("");
    const [photoURL, setPhotoURL] = useState("");
    const [buildURL, setBuildURL] = useState("");
    const [desc, setDesc] = useState("");
    const [email, setEmail] = useState("");

    var SubmitGame = (e) => {
        e.preventDefault();

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'studioName': studioName, 'gameName': gameName, 'photoUrl': photoURL, 'buildUrl': buildURL, 'description': desc, 'email': email })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + "/GameSignUp", reqOptions)
            .then((res) => res.json())
            .then((data) => {
                setSubmitted(data.success);
            });
    };

    return (
        <div className="mainPage">
            {submitted === false && <form className="gameForm" onSubmit={SubmitGame}>
                <label required htmlFor="sname">Studio Name<mark style={{ color: 'red', background: 'none' }}>*</mark></label><br />
                <input type="text" id="sname" name="sname" onChange={(e) => (setStudioName(e.target.value))} /> <br />
                <label htmlFor="email">Contact Email<mark style={{ color: 'red', background: 'none' }}>*</mark></label><br />
                <input required type="email" id="email" name="email" onChange={(e) => (setEmail(e.target.value))} /> <br />
                <label htmlFor="gname">Game Name<mark style={{ color: 'red', background: 'none' }}>*</mark></label><br />
                <input required type="text" id="gname" name="gname" onChange={(e) => (setGameName(e.target.value))} /> <br />
                <label htmlFor="desc">Game Description</label><br />
                <textarea id="gameDesc" name="gameDesc" rows="4" cols="100" onChange={(e) => (setDesc(e.target.value))} /> <br />
                <label htmlFor="photoURL">Link to Game Screenshots</label><br />
                <input type="url" id="photoURL" name="photoURL" onChange={(e) => (setPhotoURL(e.target.value))} /> <br />
                <label htmlFor="buildURL">Link to Game Build</label><br />
                <input type="url" id="buildURL" name="buildURL" onChange={(e) => (setBuildURL(e.target.value))} /> <br />
                <input name="submit" type="submit" value="Submit"/>
            </form>}
        </div>
    );
}

export default GameSignUp;
