// JavaScript source code
import './styles/TermsOfService.css';

function TOS() {

    return (
        <div className="TOSPage">
            <h1>Ludare Terms of Use</h1>

            <h3>WHAT IS LUDARE?</h3>

            <p>The Ludare platform (together with all related services, applications, plugins, etc., "Ludare") is a game support service/application that allows users ("Players") to set up a recurring monthly payment that is then divided and distributed among the games they play in proportion to the Player's playtime each month. The developers of the games played ("Developers") can receive a share of such funds by maintaining a Ludare Developer Account and incorporating Ludare identifying software into their games.</p>

            <h3>WHAT LUDARE ISN'T</h3>

            <p>Ludare is not a payment processor or platform for developing or distributing the games themselves and will not independently maintain information about a Player's funding sources or game library.  Players will still need to acquire the license to use and run the games from a legal distributor (Steam, GOG, Epic, itch, etc.) separately (and Developers will still need to make such a license available through such a distributor), and maintain and use a Stripe account in order to use Ludare.  Ludare does not provide games or downloadable content and is not a storefront. Players will not receive any additional benefits or access to their games through making a Player Account or funding a Support Amount in Ludare.</p>

            <h3>AGREEMENT TO THESE TERMS</h3>

            <p>These are Ludare's Terms of Use, and they apply to all users of the Ludare platform though certain terms may only apply to Players or Developers separately as the case may be. "We," "our," and "us" refer to Pathless Productions, Inc., the creators of Ludare. "Ludare" refers to this platform and the services offered by us, including any apps, APIs, embeds, domains, and websites. By using Ludare, you agree to these terms and to the other policies we may post, including any Security Policy, Privacy Policy, Cookie Policy, and Community Guidelines . Please read them carefully. For information about our data practices, please see our Privacy Policy and our Cookie Policy. We collect, use, and share information in accordance with those policies.</p>

            <p>Your access to and use of Ludare, including the web site at https://www.devpowered.com, the mobile website at https://www.devpowered.com (all such access points collectively and without distinction, "Ludare") is subject to your agreement to these terms.</p>

            <p>IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING OR ACCESSING LUDARE AND YOU MUST DISCONTINUE USE IMMEDIATELY.</p>

            <p>Supplemental terms and conditions or documents that may be posted in Ludare from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use from time to time. We will alert you about any changes by updating the "Last updated" date of these Terms of Use and will use commercially reasonable efforts to inform you of such change through Ludare itself.  Notwithstanding the foregoing, you expressly waive any right to receive specific notice of each such change.  As such, please ensure that you check the Terms when you use Ludare so that you understand which Terms apply. You will be subject to and will be deemed to have been made aware of and to have accepted the changes in any revised Terms of Use by your continued use of Ludare after the date such revised Terms of Use are posted.</p>

            <h3>CREATING AN ACCOUNT</h3>

            <p>When you create an account, you must provide us with complete and accurate information, in good faith, and you agree to keep your information updated if it changes. To access Ludare, you must be at least 13 years old and you must also be old enough to consent to the processing of your personal data in your jurisdiction/country (in some countries we may allow your parent or guardian to do so on your behalf). You must be at least 18 years old or have your parents' or legal guardian's permission to open an account on Ludare. You are responsible for anything that occurs when anyone is signed in to your account, as well as the security of the account. Please contact us immediately if you believe your account is compromised. You may not reveal, share or otherwise allow others to use your password or Account except as otherwise specifically authorized by us. You are responsible for the confidentiality of your login and password and for the security of your computer system. We are not responsible for the use of your password and Account or for any activity on Ludare that results from use of your login name and password by you, or by any person to whom you may have intentionally or by negligence disclosed your login and/or password in violation of this confidentiality provision. Unless it results from our negligence or fault, we are not responsible for the use of your Account by a person who fraudulently used your login and password without your permission. If you believe that the confidentiality of your login and/or password may have been compromised, you should notify us via the Ludare support page without any delay.</p>

            <p>Your Account, including any information pertaining to it is strictly personal. You may therefore not sell or charge others for the right to use your Account, or otherwise transfer your Account, nor may you sell, charge others for the right to use, or transfer any aspect of it other than if and as expressly permitted by this Agreement or as otherwise expressly permitted by us in writing.</p>

            <h3>CONDUCT</h3>

            <p>You are responsible for all activity on your account. We may terminate your account for any reason so don't do anything illegal, abusive towards others, that abuses Ludare in a technical way, or that exploits Ludare in an unintended manner that is detrimental to us. If you are a Developer earning money on Ludare, we may be exposed to risk based on what you do with those funds. As a result, we may also look at what you do outside of Ludare. These terms cover most issues, but, if you find a new and creative way to hurt us, Ludare or our community, we may take action to prevent it.</p>

            <h3>PLAYERS AND FUNDING</h3>

            <p>Upon creation of a Player Account, you will be asked to set a monthly funding amount (the "Support Amount") through the use of Stripe, Inc.'s payment platform ("Stripe").  If you are not currently a user of Stripe, you will be asked to provide information to Stripe in connection with setting up a Stripe account. You acknowledge and agree to provide complete and accurate payment information to Stripe in accordance with Stripe's terms of use and applicable law.  You also acknowledge that we are not responsible for Stripe's terms or operations, and that they may be changed by Stripe in accordance with their rules.</p>

            <p>By setting a Support Amount, you authorize us to charge such Support Amount against your Stripe Account on the day of your Ludare Player Account's creation and on the same date of each calendar month thereafter until such Funding Amount is cancelled in accordance with these terms or Ludare ceases operations (i.e., such Funding Amount is a monthly recurring payment, like a subscription).</p>

            <p>The Support Amount is intended to support the game developers that Players play by providing them with funds in addition to what those developers receive from originally selling players a license to their game.  The Support Amount does not purchase any license rights, access, or other benefits for the funding Player, and will be distributed by Ludare to Developers with Ludare accounts in proportion to a Player's playtime of such Developer's games.</p>

            <p>Stripe will typically handle payments issues such as fraud, chargebacks, and resolution of payments disputes, though, for purchases made through certain platforms (like the App Store), the associated platforms may handle these payments issues.</p>

            <h3>PLAYERS AND LINKING ACCOUNTS</h3>

            <p>In order for developers to receive a portion of a funded Support Amount, Players must indicate when they are playing a Developer-created game so that the time played can be tracked by us. Players can do this either by linking their personal storefront credentials where they are playing the game (Steam, GOG, Epic, itch, etc.) to their Ludare Player Account where specified (and by so linking such Player expressly authorizes us to track the time a Player spends playing a game on any such storefront platform, solely for the purpose of calculating the Developer Amount as described herein), or by providing their Ludare Account information at a log-in screen prepared by Developer in connection with starting their game (to be made available by Developers at their discretion.)</p>

            <p>In all such cases, Players acknowledge and agree to provide only those credentials associated with their own personal accounts and shall not counterfeit, copy, or otherwise use incomplete or incorrect credentials for this purpose.</p>

            <h3>DEVELOPERS; FUNDING; and TIME TRACKING</h3>

            <p>Game Developers can't get paid by Ludare without a Ludare Developer Account.  In order to create a Developer Account, a Developer must provide complete and accurate information sufficient for Ludare to deposit funds in a US financial institution account maintained for the benefit of Developer. By creating such an account, the individual responsible acknowledges and agrees that they control and have the right to authorize deposits into the identified US financial institution account and are legally authorized to enter into contracts on behalf of Developer.  Any breach of these representations will result in immediate termination of the associated Developer Account.</p>

            <p>As you will see below, Developers also can't get paid by Ludare unless a Player's time spent playing a Developer's game is tracked and logged by Ludare.  In connection with the creation of a Developer Account, we hereby provide Developers with licenses to software (the "Ludare Plugins") designed by us to track the time a Player spends playing a Developer's game and communicating that information to us. The licenses to such Ludare Plugins are granted solely for such purpose as integrated into Developer's games and may not be resold, distributed, or used by Developer for any other purpose.  The Ludare Plugins themselves may be updated by us from time to time at our discretion, and Developer acknowledges and agrees that the license granted herein will only apply to, and Developer will only use, the most recently updated version of the Ludare Plugins.</p>

            <p>The Ludare Plugins are provided by us "as is" and while not intended to impact the performance of Developer's game software are not guaranteed by us to be devoid of such impact.  Developers may choose to forgo the use of the Ludare Plugins and create their own software of similar functionality ("Developer Plugins"), but Developer understands and agrees that any such code must communicate time spent playing the applicable game to Ludare accurately and completely in order for Developer to be paid under these Terms.  We will provide reasonable support to confirm such functionality as and when requested by Developer, and Developer agrees not to use Developer Plugins until such functionality has been confirmed by us.</p>

            <h3>CALCULATION OF FUNDING; CREDITING DEVELOPER ACCOUNTS; DISTRIBUTION OF FUNDING</h3>

            <p>Ludare credits funded Support Amounts to Developers based on the following calculation:</p>

            <p>DA = SUP*(DTM/TTM)*APF</p>

            <p>Where:</p>

            <p>DA (Developer Amount) is the amount a Developer is credited to receive from us in respect of a given funded Support Amount.</p>

            <p>SUP (Support Amount) is the funded Support Amount of a given Player Account</p>

            <p>DTM (Developer Tracked Minutes) is the total number of minutes played by the funding Player Account in games created by the specific Developer whose Developer Amount we are calculating.</p>

            <p>TTM (Total Tracked Minutes) is the total number of minutes played by the funding Player Account in games created by all developers with a Developer Account (NOTE: time spent in games made by developers without both a Developer Account and the implementation of Ludare time tracking software (or its equivalent as described below) will not be counted for such purpose.)</p>

            <p>APF (After Platform Fee) is a ratio applied to the calculation to account for the payment of a platform fee to us.  It is 1 at present (meaning there is no Ludare fee), but we do anticipate reducing it to account for expenses in the future.  For example, a 0.95 ALF would represent a 5% platform fee to be retained by us, all of which would be earned upon funding of a Support Amount.  Note that it does not include fees applied by other platforms (App Store, etc.) which may also reduce the Developer Amount.</p>

            <p>We will use commercially reasonable best efforts to calculate all Developer Amounts and credit the same to all applicable Developer Accounts within the 60-day period following the end of the calendar month in which a Support Amount is funded. Support Amounts funded on certain platforms (like the App Store) may have different timelines associated with funds becoming accessible to you and/or may require that reserves of funds are withheld from becoming accessible to you.</p>

            <p>Developers may request that any and all Developer Amounts credited to them in excess of [$100] be deposited to the bank account associated with such Developer Account through the use of the Ludare platform.  Such deposits will be effected by us as soon as commercially reasonable.</p>

            <h3>TAX</h3>

            <p>In order to satisfy our tax obligations, we may collect tax identification information and, in certain circumstances, report this information and earnings to tax authorities as legally required.</p>
            
            <p>You agree that you will provide us with any information and documents for accurate tax determination and compliance, where and as we request or as otherwise required.</p>

            <h3>REFUNDS</h3>

            <p>Our policy is not to provide refunds, though we may allow for some exceptions where refunds are granted at our sole discretion. If you made a purchase on certain platforms (like the App Store), then Ludare may not be able to address any associated refund requests, and you may need to contact those platforms directly.</p>

            <h3>ACCOUNT DELETION</h3>

            <p>You can permanently delete your account at any time.</p>

            <p>We can terminate, delete, or suspend your account at any time at our sole discretion, but Developers will still be entitled to receive all validly credited Developer Amounts existing at the time of such termination, deletion, or suspension, provided the Developer is not otherwise in breach of these terms, our policies, or applicable law.</p>

            <p>You may not bring a claim against us for suspending or terminating your or another person's account, and you agree you will not bring such a claim. If you try to bring such a claim, you are responsible for the damages caused, including attorneys' fees and costs. These terms remain in effect even if you no longer have an account.</p>

            <h3>INTELLECTUAL PROPERTY</h3>

            <p>Developers and Players keep full ownership of their own creations, but we may need licenses from you for this material in order to operate Ludare's services effectively. If through current or future functionality a Developer or Player makes something they own available on Ludare, you grant us a royalty-free, perpetual, irrevocable, non-exclusive, sublicensable, worldwide license covering your creation or what you post in all formats and channels now known or later developed anywhere in the world to use, copy, reproduce, store, translate, transmit, distribute, perform, prepare derivative works, publicly display, and display in connection with any name, username, voice, or likeness provided in connection with it. If your creations contain any personal data, you also recognize Ludare's "legitimate interest" in it in accordance with the scope of this license.</p>

            <p>For clarity, you keep full ownership over your creations and what you post on Ludare; we are not buying your intellectual property rights or leasing them from you for our gain. We will never try to steal your creations, use them in an exploitative way, or seek to profit off of them by any means other than facilitating the operation of Ludare.</p>

            <p>You agree not to make available creations available on Ludare that infringe others' intellectual property or proprietary rights. Ludare reserves the right to remove creations and other material that violate Ludare's terms and policies, including these Terms of Use and the Community Guidelines. We respond to compliant notifications of claimed copyright infringement and have adopted and reasonably implemented a policy for terminating, in appropriate circumstances, the accounts of developers and Players who are the subject of repeated compliant notifications of claimed copyright infringement or other evidence that suggests a pattern of repeat infringement. Information regarding how this policy is implemented as well as the process for reporting or responding to notifications of claimed copyright infringement can be found here.</p>

            <p>Players may not use creations posted by Developers in any way not authorized by the developer, including sharing those creations with others.</p>

            <p>Ludare itself (and all services provided with it) are protected by copyright, trademark, patent, and trade secret laws. Some examples of our creations are the text on our websites, our iOS and Android apps, our APIs, our embeds, our logo, and our codebase. We grant Developers a limited license to use our logo and other copyrights or trademarks to promote their affiliation with Ludare and how it operates. You may not otherwise use, reproduce, distribute, perform, publicly display, or prepare derivative works of our creations unless we give you permission in writing.</p>

            <p>We welcome your feedback on the performance of the Ludare product, including any early-access, beta, or test features to which you've been given access, and your help in identifying any problems, bugs, and defects. You agree that any suggestions, comments, ideas, bug reports, or suggested improvements that you provide to us will be fully owned by us so that we can use them to improve Ludare and thereby better help the community of Developers and Players.</p>

            <h3>INDEMNITY</h3>

            <p>You will indemnify us from all third party losses and liabilities, including legal fees, that arise from these terms or relate to your use of Ludare. We reserve the right to exclusive control over the defense of a claim covered by this clause. If we use this right, then you will help us in our defense. Your obligation to indemnify under this clause also applies to our subsidiaries, affiliates, officers, directors, employees, agents, and third-party service providers.</p>

            <h3>WARRANTY DISCLAIMER</h3>

            <p>Ludare (including all related services and software) is provided "as is" and without warranty of any kind. Any warranty of merchantability, fitness for a particular purpose, non-infringement, and any other warranty, whether express or implied, is excluded and disclaimed to the greatest extent permitted by law. The disclaimers of warranty under this clause also apply to our subsidiaries, affiliates, and third-party service providers.</p>

            <h3>LIABILITY LIMITATION</h3>

            <p>To the greatest extent permitted by law, we are not liable to you for any incidental, consequential, special, or punitive damages arising out of these terms, or your use or attempted use of Ludare. To the greatest extent permitted by law, our liability for damages is limited to the amount of money we have earned through your use of Ludare during the 12 month period prior to any claim you might make. We are specifically not liable for loss associated with unfulfilled offerings and benefits and from losses caused by conflicting contractual agreements. For this clause, "we" and "our" is defined to include our subsidiaries, affiliates, officers, directors, employees, agents, and third-party service providers.</p>

            <h3>DISPUTE RESOLUTION; ARBITRATION; WAIVERS (JURY TRIAL - CLASS ACTION)</h3>

            <p>We encourage you to contact us if you have an issue. If a dispute does arise out of these terms or related to your use of Ludare, and it cannot be resolved through mutual agreement, then you and we agree that it must be resolved by arbitration to be administered by JAMS in Washtenaw county Michigan under the JAMS Streamlined Arbitration Rules and Procedures, except as expressly provided below. Judgment on the arbitration may be entered in any court of competent jurisdiction. All arbitrations may take place only on an individual basis; no class creation or grouping of individual parties will be permitted. By agreeing to these terms and using Ludare you are waiving your right to a trial by jury or to participate in a class action or representative proceeding; we are also waiving those rights.</p>

            <p>Michigan law, excluding its conflict of law provisions, governs these terms, all other Ludare policies, and any dispute that arises between you and Ludare.</p>

            <p>Application of the United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transaction Act (UCITA) are excluded from these Terms of Use. In no event shall any claim, action, or proceeding brought by either party related in any way to the Application be commenced more than one (1) years after the cause of action arose.</p>

            <p>This provision does not limit your or our ability to file an action in a court of competent jurisdiction to seek equitable or injunctive relief for disputes relaring to intellectual property, data, or this provision itself.  In any such action, we and you agree that the court (rather than an arbitrator) should decide whether a claim is required to be arbitrated, as well as whether or not a party is enititled to the equitable or injunctive relief requested.</p>

            <h3>MISCELLANEOUS</h3>

            <p>These terms and any referenced policies are the entire agreement between you and us, supersede all prior agreements, and don't create any partnership, joint venture, employee-employer or franchiser-franchisee relationship between you and us. If any provision of these terms is held to be unenforceable, then that provision is modified to the extent necessary to enforce it. If a provision cannot be modified to make it enforceable, then it is severed from these terms, and all other provisions remain in force. If either party fails to enforce a right provided by these terms, then it does not waive the ability to enforce any rights in the future.</p>

            <p>If you sign-up to receive text messages from us, you agree to receive recurring automated promotional and personalized marketing text (e.g., SMS and MMS) messages from us, including text messages that may be sent using an automatic telephone dialing system, to the mobile telephone number you provided when signing up or any other number that you designate. Consent to receive automated marketing text messages is not a condition of any purchase. Message and data rates may apply. Message frequency may vary. We reserve the right to alter the frequency of messages sent at any time, so as to increase or decrease the total number of sent messages. We also reserve the right to change the short code or phone number from which messages are sent. Not all mobile devices or handsets may be supported and our messages may not be deliverable in all areas. We, our service providers, and the mobile carriers supported by the program are not liable for delayed or undelivered messages.</p>
        </div>
    );

}

export default TOS;
