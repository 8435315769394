// JavaScript source code
import './styles/Privacy.css';

function PrivacyPolicy() {

    return (
        <div className="privacyPage">
            <h1>
                Ludare Privacy Policy
            </h1>
            <p>
                This Policy was last modified on May 13, 2024.
            </p>
            <p>
                We at Pathless Productions Inc, ("Company", "We", "Pathless Productions", or "Ludare") respect your privacy and are committed to protecting it through compliance with this policy. This policy describes the types of information we may collect or that you may provide when you register with, use, or access the Ludare.com website or log in through the games running our backend. This policy also describes our practices for collecting, using, protecting, disclosing and maintaining that information.
            </p>
            <p>
                We utilize payment processors who have their own privacy policies and we encourage you to read their policies before providing information on or through them.
            </p>
            <p>
                Read this policy carefully to understand our policies and practices regarding your information and how we will treat that information. If your do no agree with our policies and practices, please do not register with or use Ludare. By registering or using Ludare, you agree to this policy. This Policy may change over time with updates appearing on the Website. Your continued use of Ludare after changes are made is deemed as acceptance of those changes. So, please check this policy periodically.
            </p>
            <h1>
                Children Under the Age of 13
            </h1>
            <p>
                Ludare is not intended for children under 13 years of age and we do not knowingly collect personal information from children under 13. If we learn we have collected or received personal information from a child under 13 without parental consent, we will delete that information. If you believe we may have any information from or about a child under 13. Please contact use at contact@pathlessproductions.com.
            </p>
            <h1>
                Information We Collect and How We Collect It
            </h1>
            <p>
                We collect information from and about users of Ludare.
            </p>
            <ul>
                <li>
                    1.	Directly from you when you provide it to us.
                </li>
                <li>
                    2.	Automatically when you use certain parts of Ludare.
                </li>
            </ul>
            <h1>
                Information You Provide to Us
            </h1>
            <p>
                When you register or use Ludare, we may ask you to provide information:
            </p>
            <ul>
                <li>
                    1.	By which you may be personally identified such as your name, email address, postal address, payment service provider account information, and any other identifier by which you may be contacted online or offline
                </li>
                <li>
                    2.	Information that your provide by filling in forms in Ludare. This includes information provided at the time of registering to use Ludare, if your request further services from Ludare, enter a contest or promotion sponsored by Ludare, or report a problem with Ludare.
                </li>
                <li>
                    3.	Records and copies of correspondence (including email addresses or phone numbers), if you contact us.
                </li>
                <li>
                    4.	You responses to surveys we might as you to complete for research purposes.
                </li>
                <li>
                    5.	Details of transactions carried out through Ludare and play analytics when you log into a game running our integration.
                </li>
            </ul>
            <h1>
                Automatic Information Collection and Tracking
            </h1>
            <p>
                When you download, access, and use Ludare, it may use technology to automatically collect:
            </p>
            <ul>
                <li>
                    1.	Usage Details: When you access and use Ludare or the Ludare game integrations, we may automatically collect certain details of your access and use of Ludare. Including traffic data, location data, logs, and other data and resources that you access or use on or through Ludare.
                </li>
                <li>
                    2.	Device Information: We may collect information about your accessing device and internet connection including IP addresses and browser type.
                </li>
            </ul>
            <h1>
                Information Collection and Tracking Technologies
            </h1>
            <p>
                The technologies we use for automatic information collection may include:
            </p>
            <ul>
                <li>
                    1.	Cookies. A cookie is a small file places on your device. It is possible to refruse to accept cookies. However, this may make you unable to access certain parts of Ludare. We utilize Google Analytics in our data collection.
                </li>
            </ul>
            <h1>
                Third-Party Information Collection
            </h1>
            <p>
                When you use Ludare, certain third parties may use automatic information collection technologies to collect information about you. These third parties include:
            </p>
            <ul>
                <li>
                    1.	Game platforms like Steam or Epic Game Store when you link your accounts to Ludare.
                </li>
                <li>
                    2.	Analytics companies like Google Analytics.
                </li>
                <li>
                    3.	Payment providers like Stripe.
                </li>
            </ul>
            <p>
                These third parties may use tracking technologies to collect information about you when you use Ludare. This information they collect may be associated with your Personal Information or they may collect information, including Personal Information, about your online activities over time and across different websites or apps. They might use this information to provide you with interest-based advertising or other targeted content.
            </p>
            <p>
                We do not control these third parties' tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.
            </p>
            <h1>
                How We Use Your Information
            </h1>
            <p>
                We use information we collect about you or that your provide to us, including any Personal Information to:
            </p>
            <ul>
                <li>
                    1.	Provide you with the Ludare service and calculate engagement with your favorite titles.
                </li>
                <li>
                    2.	Fulfill any other purpose for which your provide it.
                </li>
                <li>
                    3.	Give you notices about your account.
                </li>
                <li>
                    4.	Carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including billing and collection.
                </li>
                <li>
                    5.	Notify you when Ludare updates are available.
                </li>
                <li>
                    6.	Improve the Ludare experience.
                </li>
            </ul>
            <p>
                The usage information we collect helps us to improve Ludare and to deliver a better experience by enabling us to:
            </p>
            <ul>
                <li>
                    1.	Estimate our audience size and usage.
                </li>
                <li>
                    2.	Recognize you when you use the service.
                </li>
                <li>
                    3.	Track your play patterns and pay developers based on that.
                </li>
            </ul>
            <h1>
                Disclosure of Your Information
            </h1>
            <p>
                We not intend to sell or market your Personal Information. However, through providing access to Ludare, we may disclose aggregated information about our users in order to deliver Ludare, including the following:
            </p>
            <ul>
                <li>
                    1.	To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep Personal Information confidential and use it only for the purposes for which we disclose it to them.
                </li>
                <li>
                    2.	To a buyer or other successor in the event of a merger, divestiture, restructuring, reorginzation, dissolution, or other sale or transfer of some or all of Pathless Productions assets, whether as a going concern or as a part of bankruptcy, liquidation, or similar proceeding, in which Personal Information held by Pathless Productions about Ludare users is among the assets transferred.
                </li>
                <li>
                    3.	To fulfill the purpose for which your provide it.
                </li>
                <li>
                    4.	For any other purpose disclosed by us when you provide the information.
                </li>
                <li>
                    5.	With your consent.
                </li>
                <li>
                    6.	To comply with any court order, law, or legal process, including to respond to any government or regulatory request.
                </li>
                <li>
                    7.	To enforce our rights arising from any contract entered into between you and us, including the Ludare Terms of Service, and for billing and collection.
                </li>
                <li>
                    8.	If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Ludare, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
                </li>
            </ul>
            <h1>
                Your Choices About Our Collection, Use, and Disclosure of Your Information
            </h1>
            <p>
                We aim to provide you with choices regarding the Personal Information you provide to us. This section describes mechanisms we provide for you to control certain uses and disclosures of your information.
            </p>
            <ul>
                <li>
                    1.	Tracking Technologies. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. You can choose whether or not to allow the Service to collect information through other tracking technologies by selecting to opt out via your online account. If you disable or refuse cookies or block the use of other tracking technologies, some part of Ludare may then be inaccessible or no function properly.
                </li>
                <li>
                    2.	Promotion by the Company. If you do not want us to use your email address to promote our own or third parties� products or services, you can opt-out by de-selecting the option in your user account. You can always opt-out by logging into the Service and adjusting your user preferences in your account profile by checking or unchecking the relevant boxes. We reserve the right to send you emails about important or necessary changes to your account or service. For example, to inform you of payment issues or copyright claims about your content.
                </li>
            </ul>
            <h1>
                Accessing and Correcting your Personal information.
            </h1>
            <p>
                You can review and change your Personal information by logging into the Service and visiting your account settings page. You may also send us an email at contact@pathlessproductions.com to request access to, correct, port, delete, or restrict use of any Personal Information that you have provided to us. We cannot delete all of your Personal Information except by also deleting your user account. We may not accommodate a reauest to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.
            </p>
            <h1>
                Your California Privacy Rights
            </h1>
            <p>
                California Civil Service Code Section 1798.83 permits users of Ludare that are Clifornia residents to request certain information regarding our disclosure of Personal Information to third parties for their direct marketing purposes. To make such a request, please send an email to contact@pathlessproductions.com.
            </p>
            <h1>
                EU Users
            </h1>
            <p>
                If you are using Ludare from the European Union ("EU") or other regions with laws governing data collection and use that may differ from EU or United States law, please note that you may be transferring your Personal Information to Pathless Productions in the United States for the purposes described under this Privacy Policy. The legal basis we rely on to use your Personal Information is as follows:
            </p>
            <ul>
                <li>
                    1.	Performance of a contract. You Personal Information is necessary to perform the terms and conditions or other policies under whit we provide Ludare.
                </li>
                <li>
                    2.	Consent. We rely upon your consent to use technical information such as cookie data and ip geo-location data and your Personal Information for marketing purposes. You may withdraw your consent at any time by contacting us at contact@pathlessproductions.com or by using the unsubscribe link in any marketing communication you receive from us.
                </li>
                <li>
                    3.	Legitimate interests. We may use your Personal Information for our legitimate interests to improve our services, security purposes, fraud prevention, and for internal administration.
                </li>
            </ul>
            <h1>
                Data Security
            </h1>
            <p>
                We have implemented measures designed to secure your Personal Information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure services behind firewalls. Any payment transactions will be excrypted using SSL technology.
            </p>
            <p>
                The safety and security of your information also depends on you. Where you have chosen a password for access to certain parts of Ludare, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. Please be careful about giving out information in public areas.
            </p>
            <p>
                Unfortunately, the transmission of information via the internet and mobile platforms is not completely secure. Although we try to protect your Personal Information, we cannot guarantee the security of our Personal Information transmitted through Ludare. Any transmission of Personal Information is at your own risk. We are not responsible for the circumvention of any privacy settings or security measures we provide.
            </p>
            <h1>
                Changes to Our Privacy Policy
            </h1>
            <p>
                We may update our privacy policy from time to time. If we make material changes to how we treat our users' Personal Information, we will post the new privacy policy on this page with a notice that the privacy policy has been updated and notify you by the email specified in your account.
            </p>
            <p>
                The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you and for periodically visiting the privacy policy to check for any changes.
            </p>
            <h1>
                Contact Information
            </h1>
            <p>
                To ask questions or comment about this privacy policy and our privacy practices, contact us at: <a href="mailto:contact@pathlessproductions.com">contact@pathlessproductions.com</a>.
            </p>
        </div>
    );

}

export default PrivacyPolicy;
