// JavaScript source code
import './styles/StudiosPage.css';
import GameImageHeader from './GameImageHeader';
import { useState, useRef } from 'react';
import Cookies from 'js-cookie';

function StudiosPage() {
    const devName = useRef();
    const devEmail = useRef();
    const [caseStudyIndex, setCaseStudyIndex] = useState(0);
    const caseStudyData = [{ 'name': "Valheim", 'image': "Valheim.jpg", 'projections': "3-10" }, { 'name': "The Pale Beyond", 'image': "PaleBeyond.jpg", 'projections': "1-4" }];

    function ResourcesSubmit(event) {
        event.preventDefault();

        console.log("submit");

        const signUpReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'developerName': devName.current, 'email': devEmail.current })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/ResourcesSignUp', signUpReqOptions)
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                if (data.success === true) {
                    Cookies.set("ResourcesSignUp", true);
                    window.location.assign("/StudioTools");
                }
            });
    }

    function onValueChange(event) {
        setCaseStudyIndex(event.target.value);
    }

    return (
        <div className="studioPage">
            <div className="titleLineStudio">
                <GameImageHeader />
                <div className="topBodyStudio">
                    <div className="infoStudio">
                        <h2 className="taglineTopStudio">Launch Your Game</h2>
                        <h2 className="taglineBottomStudio">And Earn As Its Played</h2>
                        <p className="subTagLineStudio">Monetize your game based on time played and <br /> join a community of passionate indie developers.</p>
                    </div>
                </div>
            </div>
            <div className="studioTestimonials">
                <h1 className="testimonialsHeader">What Developers Are Saying</h1>
                <p className="testimonialsDesc">Joing Ludare and unlock your game's full potential with the support of a thriving community. Here's why developers love working with us:</p>
                <div className="studioTestimonialObj">
                    <img className="testimonialPicture" src="AzureRavens.png"/>
                    <div className="testimonialInfo">
                        <p className="testimonialStudioPageName">Azure Ravens</p>
                        <p className="testimonialText">...it's going to allow us to start getting players in playtesting the game, giving us feedback, generating that initial revenue that'll help us focus more on this.</p>
                        <p className="testimonialAttribution">Corbin Reeves</p>
                    </div>
                </div>
            </div>
            <div className="caseStudies">
                <h1 className="caseStudiesHeader">Potential Impacts on Revenue</h1>
                <p className="caseStudiesDesc">Here's how revenue would change for some games under the Ludare system.</p>
                <div className="caseStudiesObj">
                    <div className="caseStudiesInfo">
                        <img className="caseStudyPicture" src={caseStudyData[caseStudyIndex].image} />
                        <div className="CaseStudyRevInfo">
                            <p className="caseStudyGameName">{caseStudyData[caseStudyIndex].name}</p>
                            <p className="caseStudyResults">{caseStudyData[caseStudyIndex].projections} x More</p>
                            <p className="castStudyDisclosure">Based on historic play data and projected Steam sales revenue.</p>
                        </div>
                    </div>
                    <form className="casStudiesSelectorsForm" >
                        {caseStudyData.map((data, index) => (
                            <input className="coloredradio" onClick={onValueChange} type="radio" id="image1" name="image" value={index} checked={index === caseStudyIndex} />
                        ))}
                    </form>
                </div>
            </div>
            <div className="studioInfoContainer">
                <h1 className="studiosSectionHeader">Register Now To Get Access To All Materials</h1>
                <p className="studioCallToActionDesc">Sign up to get access to more studio testimonials and tools to evaluate </p>
                <form className="studioResourceAccessForm">
                    <label className="studioRsourceSignUpLabel" htmlFor="name">Developer's Name:</label>
                    <input className="stuioResourceSignUpInput" type="text" id="name" onChange={(e) => devName.current = e.target.value} />
                    <label className="studioRsourceSignUpLabel" htmlFor="email">Email:</label>
                    <input className="stuioResourceSignUpInput" type="email" id="email" onChange={(e) => devEmail.current = e.target.value} />
                    <input className="studioResourceSignUpSubmit" type="submit" onClick={ResourcesSubmit} />
                </form>
            </div>
        </div>
    );
}

export default StudiosPage;

/*
<h1 className="studiosSectionHeader">Register Now To Get Access To All Materials</h1>
                <p className="studioCallToActionDesc">Sign up to get access to more studio testimonials and tools to evaluate </p>
                <form className="studioResourceAccessForm">
                    <label className="studioRsourceSignUpLabel" htmlFor="name">Developer's Name:</label>
                    <input className="stuioResourceSignUpInput" type="text" id="name" onChange={(e) => devName.current = e.target.value} />
                    <label className="studioRsourceSignUpLabel" htmlFor="email">Email:</label>
                    <input className="stuioResourceSignUpInput" type="email" id="email" onChange={(e) => devEmail.current = e.target.value} />
                    <input className="studioResourceSignUpSubmit" type="submit"/>
                </form>

                <div className="studioInfoContainer">
                <div className="applyStep">
                    <div className="applyTile">
                        <img className="applyIcon" src="web-browser-recolored.png" />
                        <p className="applyTileInfo">To become recognized as a developer with Ludare, set up an account and submit a developer request.
                            <br /> <br />
                            Ludare reviews applications promptly and will inform you of your status soon. Once recognized, you will receive all necessary resources to get started.</p>
                    </div>
                    <div className="applyMap">
                        <h1 className="applyStepNum">01.</h1>
                        <p className="applyStepTitle">APPLY FOR DEVELOPER ACCESS</p>
                        <img className="applyMapImage" src="MapLine1.png" />
                    </div>
                </div>
                <div className="pluginStep">
                    <div className="pluginMap">
                        <h1 className="pluginStepNum">02.</h1>
                        <p className="pluginStepTitle">INTEGRATE THE PLUGIN</p>
                        <img className="pluginMapImage" src="MapLine2.png" />
                    </div>
                    <div className="pluginTile">
                        <img className="pluginIcon" src="plug-recolored.png" />
                        <p className="pluginTileInfo">After being accepted into the developer program, download the plugin for your engine or language.
                            <br /> <br />
                            The plugins are mostly self-maintaining, but you'll need to ensure they run properly during gameplay and pass the correct identifiers to track the game being played.</p>
                    </div>
                </div>
                <div className="updateStep">
                    <div className="updateTile">
                        <img className="updateIcon" src="cloud-upload-recolored.png" />
                        <p className="updateTileInfo">After successful integration and testing, release an update for your game. Players will then be able to register their play. You can monitor player activity and earnings through your developer dashboard.</p>
                    </div>
                    <div className="updateMap">
                        <h1 className="updateStepNum">03.</h1>
                        <p className="updateStepTitle">UPDATE YOUR GAME</p>
                    </div>
                </div>
            </div>
            <div className="supportedEnginesContainer">
                <h1 className="supportedEnginesTitle">SUPPORTED ENGINES</h1>
                <div className="unityContainer">
                    <img className="unityLogo" src="U_Logo_White_RGB.png" />
                </div>
                <div className="unrealContainer">
                    <img className="unrealLogo" src="UE_Logo_icon-only_white.png" />
                    <p className="unrealTitle">Unreal</p>
                </div>
                <div className="godotContainer">
                    <img className="godotLogo" src="GodotLogo.png" />
                </div>
            </div>
*/

        /**/