// JavaScript source code
import "./styles/NewsletterSignUp.css";
import { useState, useRef } from 'react';

function NewsletterSignUp(){
    const [registered, setRegistered] = useState(false);
    const [error, setError] = useState(null);
    const email = useRef();
    const firstName = useRef();
    const lastName = useRef();

    function SubmitNewsletterSignUp(e) {
        e.preventDefault();

        const signUpReqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'email': email.current, 'firstName': firstName.current, 'lastName': lastName.current })
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/AddToNewsletter', signUpReqOptions)
            .then((res) => res.json())
            .then((data) => {
                //console.log(data);
                
                if (data.success === true) {
                    setRegistered(true);
                }
                else {
                    setError(true);
                }
            });
    }

    return (
        <div className="newsletterPage">
            <h1 className="newsletterTitle">Stay up to date!</h1>
            {registered === false &&
                <form className="newsletterForm" onSubmit={SubmitNewsletterSignUp}>
                    <div className="newsletterEmailBlock">
                        <label htmlFor="email" className="newsletterEmailLabel">Email*</label>
                        <input required id="email" className="newsletterEmailField" type="email" onChange={(e) => email.current = e.target.value } />
                    </div>
                    <div className="newsletterNameBlock">
                        <div className="newsletterFirstNameBlock">
                            <label htmlFor="firstName" className="newsletterFirstNameLabel">First Name*</label>
                            <input required id="firstName" className="newsletterFirstNameField" type="text" onChange={(e) => firstName.current = e.target.value} />
                        </div>
                        <div className="newsletterLastNameBlock">
                            <label htmlFor="lastName" className="newsletterLastNameLabel">Last Name*</label>
                            <input required id="lastName" className="newsletterLastNameField" type="text" onChange={(e) => lastName.current = e.target.value} />
                        </div>
                    </div>
                    <input type="submit" className="newsletterSubmitButton" />
                    {error !== null &&
                        <h1 className="newsletterError">Failed to Register!</h1>
                    }
                </form>
            }
            {registered === true &&
                <h1 className="registrationResult">Successfully Registered!</h1>    
            }
        </div>
    );
}

export default NewsletterSignUp;