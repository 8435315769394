// JavaScript source code
import './styles/Library.css';
import { useState, useRef, useEffect } from 'react';
import Dropdown from './components/library/Dropdown.js'
import GameCard from './components/library/Game.js'
import { GameCardInfo } from './components/library/Game.js'
import GameImageHeader from './GameImageHeader';

function LibraryPage() {
    const [popupInfo, setPopupInfo] = useState(null);
    const [launchedLibrary, setLaunchedLibrary] = useState([]);
    const [comingSoonLibrary, setComingSoonLibrary] = useState([]);
    // const images = useRef([
    //     new GameCardInfo("sweetbrew.png", ['Visual Novel', 'Otome', 'LGBTQ+', 'Romance'], 'Sweetcore Brews', 'Nochi Studios', { steamLink: 'https://store.steampowered.com/app/2562430/Sweetcore_Brews__an_otome_game_inspired_by_witchy_sitcoms/' }, 'An anime, choice-driven witchy otome visual novel where you bond and interact with two romance-able characters to unlock romantic scenarios in the story! Play minigames to experience branching relationship routes (GxB or GxG)', ["sweetbrew.png"]),
    //     new GameCardInfo("ravenwoodacres.png", ['Simulation', 'Casual', 'RPG', 'Farming Sim'], 'Ravenwood Acres', 'Azure Ravens Entertainment', { steamLink: 'https://store.steampowered.com/app/2526990/Ravenwood_Acres/' }, 'Ravenwood Acres is a relaxed fantasy reimaging of the farm sim genre. Write your own story in this vibrant frontier town; your life is at your fingertips. Will you train to become a legendary crafter, brave the wilderness as an adventurer, or live a quiet life on the farm?', ["ravenwoodacres.png"]),
    // ]);

    // useEffect(() => {
    //     const fetchGameAndDeveloper = async () => {

    //     };

    //     fetchGameAndDeveloper();
    //   }, [gameId]);

    useEffect(() => {
        const gameDataOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/GetGamesLibrary', gameDataOptions)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    setLaunchedLibrary(data.message.filter((game) => game.DeveloperID !== 5));
                    setComingSoonLibrary(data.message.filter((game) => game.DeveloperID === 5));
                    //console.log(data.message);
                }
            });
    }, []);

    return (
        <div className="LibraryPage">
            <div className="titleLineLibrary">
                <GameImageHeader />
                <div className="topBodyLibrary">
                    <div className="infoLibrary">
                        <h2 className="taglineTopLibrary">Explore</h2>
                        <h2 className="taglineBottomLibrary">Ludare Games</h2>
                        <p className="subTagLineLibrary">Explore new realms, challenge your skills, and find the <br /> perfect game for you.</p>
                    </div>
                </div>
            </div>

            {launchedLibrary.length > 0 && <div className='libraryTitle'>
                <h1> Supported</h1>
                <h1 style={{ color: '#FFCF00', marginLeft: '15px' }}>Games</h1>
            </div>}

            {launchedLibrary.length > 0 && <div className="catalogue">

                {launchedLibrary.map((gameInfo, index) => {
                    return <GameCard gameId={gameInfo.ID} gameInfo={gameInfo} />
                })}
            </div>}

            <div className='libraryTitle'>
                <h1> Coming Soon</h1>
            </div>

            <div className="catalogue">

                {comingSoonLibrary.map((gameInfo, index) => {
                    return <GameCard gameId={gameInfo.ID} gameInfo={gameInfo} />
                })}
            </div>


            {/* <div className='comingSoon'>
                <div className='comingTitle'>
                    <h1> Coming </h1>
                    <h1 className='soon'> Soon</h1>
                </div>
                <div>
                    <GameCard />
                </div> */}
            {/* </div> */}
        </div >
    );
}

export default LibraryPage;
