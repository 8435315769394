// JavaScript source code
import "./styles/GameDownloads.css";
import { useEffect, useState, useRef } from 'react';
import { GetAccessToken, IsValid, GetGame, GetDeveloperInfo, GetTagInfo } from "./Utilities";
import Popup from './components/library/Popup';
//import FetchAccountInfo from './AccountPage';

function FetchAccountInfo(setAccountInfo) {

    var URL = process.env.REACT_APP_NODE_ADDRESS + "/GetAccountInfo";
    //console.log(Cookies.get("AccessToken"));
    GetAccessToken().then((token) => {
        //console.log(Cookies.get("AccessToken"));
        //console.log(token);


        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'token': token })
        };

        fetch(URL, reqOptions)
            .then((res) => res.json())
            .then((data) => { setAccountInfo(data.message); });
    });
}

function GameDownloads() {
    const [gameList, setGameList] = useState([]);
    const [accountInfo, setAccountInfo] = useState();
    const [subscriptionInfo, setSubscriptionInfo] = useState(null);
    const [tags, setTags] = useState([]);

    useEffect(() => {
        FetchAccountInfo(setAccountInfo);
        const req = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: ""
        }

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/GetTagInfo', req)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    data.message.sort((a, b) => {
                        if (a.id < b.id) {
                            return -1;
                        }
                        if (a.id > b.id) {
                            return 1;
                        }
                        return 0;
                    });
                    setTags(data.message);
                }
            });

    }, []);

    useEffect(() => {
        //console.log(accountInfo);

        var URL = process.env.REACT_APP_NODE_ADDRESS + "/GetGamesLibrary";
        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch(URL, reqOptions)
            .then((res) => res.json())
            .then((data) => {
                data.message.map((game, index) => {
                    game.PublicBuildLinks = JSON.parse(game.PublicBuildLinks);
                });

                setGameList(data.message);
            });

        if (IsValid(accountInfo) === true) {
            var subURL = process.env.REACT_APP_NODE_ADDRESS + "/GetSubscription";
            const subReqOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'customer': accountInfo.StripeID })
            };

            fetch(subURL, subReqOptions)
                .then((res) => res.json())
                .then((data) => {
                    //console.log(data);
                    if (data.success === true) {
                        //console.log(data.message);
                        setSubscriptionInfo(data.message);
                    }
                    else {
                        setSubscriptionInfo(false);
                    }
                });
        }

    }, [accountInfo]);

    return (
        
        <div className="downloadPage">
            {IsValid(accountInfo) === true && IsValid(subscriptionInfo) === true && subscriptionInfo !== false && gameList.filter((game, index) => game.DeveloperID !== 5).map((game, index) => {
                return <GameDownloadEntry gameInfo={game} tags={tags} />;
            })}
            {accountInfo === null &&
                <p className="notSignedInMessage">Please sign in or activate your account</p>
            }
            {IsValid(subscriptionInfo) === true && subscriptionInfo === false && 
                <a href={process.env.REACT_APP_BASE_ADDRESS + "/account/?loadPage=Subscription"} className="inactiveSubscriptionMessage">You don't have an active subscription. Click here to go to your subscription page.</a>
            }
        </div>
    );
}

function GameDownloadEntry({ gameInfo, tags }) {
    const [parsed, setParsed] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [gameData, setGameData] = useState(null);
    const [developerInfo, setDeveloperInfo] = useState(null);

    useEffect(() => {
        //console.log(gameInfo);
        const fetchGameAndDeveloper = async () => {
            try {
                const game = await GetGame(gameInfo.ID);
                if (!game) {
                    throw new Error('Game not found');
                }
                setGameData(game.message);

                const developer = await GetDeveloperInfo(game.message.DeveloperID);
                if (!developer) {
                    throw new Error('Developer not found');
                }
                setDeveloperInfo(developer.message);
            } catch (err) {
                console.log(err.message);
            }
        };

        fetchGameAndDeveloper();

    }, [gameInfo])

    function HandleClick() {
        setShowPopup(!showPopup);
    }

    return (
        <div>
            {
                showPopup === true && (
                    <Popup handleClick={HandleClick} gameData={gameData} tagData={tags} developerInfo={developerInfo} />
                )
            }
            {IsValid(gameInfo.PublicBuildLinks) === true && gameInfo.PublicBuildLinks.length > 0 && <div className="gameDownloadEntry">
            
                <p className="gameDownloadName">{gameInfo.Name}</p>
                <p className="gameInfoLink" onClick={HandleClick}>View Game Information</p>
                <p className="gameDownloadLabels">Downloads</p>
                
                {gameInfo.PublicBuildLinks.map((link, index) => {
                    return <a className="gameDownloadLink" href={process.env.REACT_APP_S3_BASE_URL + link} download>{link.split('/').pop()}</a>;
                })}
                
            </div>}
        </div>
    );
};

export default GameDownloads;