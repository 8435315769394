// JavaScript source code
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import FetchAccountInfo from './AccountPage';

function EosReturn() {
    const [queryParams] = useSearchParams();
    const [idSet, setIdSet] = useState(false);

    useEffect(() => {
        
    }, []);

    return (
        <div>
            {idSet === true && <Navigate to="/account?loadPage=LinkAccounts" />}
        </div>
    );
}

//

export default EosReturn;