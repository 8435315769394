import React, { useState, useEffect } from 'react';
import { GetGame, GetDeveloperInfo, GetTagInfo } from "../../Utilities";
import Popup from './Popup.js'
import { IsValid } from '../../Utilities.js';
import '../../styles/Game.css';

 const GameCard = ({ gameId, gameInfo }) => {
    const [gameData, setGameData] = useState(null);
    const [developerInfo, setDeveloperInfo] = useState(null)
    const [error, setError] = useState(null);
    // NEW HERE FOR POPUP
    const [showPopup, setShowPopup] = useState(false);
    const [tagData, setTagData] = useState([]);
    const [tags, setTags] = useState([]);

    useEffect(() => {
        const fetchGameAndDeveloper = async () => {
            try {
                var game;
                if (IsValid(gameId) === true) {
                    game = await GetGame(gameId);
                    if (!game) {
                        throw new Error('Game not found');
                    }
                    setGameData(game.message);
                }
                else {
                    game = { message: gameInfo };
                    setGameData(gameInfo);
                }

                const developer = await GetDeveloperInfo(game.message.DeveloperID);
                if (!developer) {
                    throw new Error('Developer not found');
                }
                setDeveloperInfo(developer.message);

                //console.log("WOWWWWW", game.message.TagId);
                const tagNames = await GetTagInfo(game.message.TagId);

                setTagData(tagNames);
                console.log(tagNames);


            } catch (err) {
                setError(err.message);
            }
        };

        const req = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: ""
        }

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/GetTagInfo', req)
            .then((res) => res.json())
            .then((data) => {
                if (data.success === true) {
                    data.message.sort((a, b) => {
                        if (parseInt(a.id) < parseInt(b.id)) {
                            return -1;
                        }
                        if (parseInt(a.id) > parseInt(b.id)) {
                            return 1;
                        }
                        return 0;
                    });
                    setTags(data.message);
                }
            });

        fetchGameAndDeveloper();
    }, [gameId, gameInfo]);

    useEffect(() => {
        GetTagInfo(gameInfo.TagId)
            .then((data) => {
                setTagData(data);
                console.log(data);
            });
    }, [gameInfo]);

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!gameData || !developerInfo) {
    return <div>Loading...</div>;
  }

  const { Name, TagId = [], Description, PhotoUrl, SteamLink, EpicLink } = gameData;
  const { DevName } = developerInfo;

  const fullPhotoLink = `${process.env.REACT_APP_S3_BASE_URL}${PhotoUrl}`
  const maxLength = 70;
  const truncatedDescription =
    IsValid(Description) === false ? "" : ( Description.length > maxLength
      ? Description.substring(0, maxLength - 10) + "... " //can update this logic to cut to the last word instead of characters
      : Description);

  const seeMore = IsValid(Description) === false ? "" : ( Description.length > maxLength ? (
    <span className="see-more">See More</span>
  ) : null);


  const handleClick = () => {
    setShowPopup(!showPopup);
  };



  return (
    <div>
        {
          showPopup && (
                  <Popup handleClick={handleClick} gameInfo={gameInfo} gameData={gameData} tagData={tagData} developerInfo={developerInfo} tags={tags} />
          )
        }
      <div className="game-card" onClick={handleClick} style={{ cursor: 'pointer' }}>
        <div className="game-card-image">
          <img src={fullPhotoLink} alt="Game cover" />
        </div>
        <div className="game-card-title">
          <text>{Name}</text>
        </div>
              <div className='game-card-content'>
                <div className='game-card-dev-info'>
                    <div className='game-card-studio-div'>
                        <p className="game-card-studio">Studio:</p>
                        <p className="game-card-studio-name">{gameData.DeveloperNameOverride || DevName}</p>
                    </div>
                    {gameData.PublisherName !== undefined && gameData.PublisherName !== "" && gameData.PublisherName !== null && <div className='game-card-publisher-div'>
                        <p className="game-card-publisher">Publisher:</p>
                          <p className="game-card-publisher-name">{gameData.PublisherName}</p>
                    </div>}
                </div>
          <div className="game-card-tags">
            {IsValid(gameInfo.TagId) === true && gameInfo.TagId.split(',').map((tag, index) => (
              <span className="game-card-tag">{tags[tag].TagName}</span>
            ))}
          </div>
          <p className="game-card-description">
            {truncatedDescription}{seeMore && <>{seeMore}</>}
          </p>
          <div className="game-card-platforms">
            {SteamLink && (
              <a href={SteamLink} target="_blank" rel="noopener noreferrer">
                <img
                  src={`${process.env.PUBLIC_URL} steamIcon.png`}
                  alt="Steam"
                  className="platform-logo"
                />
              </a>
            )}
            {EpicLink && (
              <a href={EpicLink} target="_blank" rel="noopener noreferrer">
                <img
                  src={EpicLink}
                  alt="Epic Games"
                  className="platform-logo"
                />
              </a>
            )}
          </div>
          </div>
      </div>
    </div>
  );
};

export default GameCard;

