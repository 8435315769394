// JavaScript source code
import './styles/Footer.css';

function Footer() {

    return (
        <footer className="footerDiv">
            <ul className="footerList">
                <li className="footerLogo"><img src="LudareLogoTransparent.png" /></li>
                <li className="mediaLinks">
                    <div>
                        <a href="https://twitter.com/LudareOfficial"><img src="twitter.png" alt="X logo" /></a>
                        <a href="https://www.tiktok.com/@ludareofficial"><img src="tik-tok.png" alt="TikTok logo" /></a>
                        <a href="https://www.instagram.com/ludareofficial/"><img src="instagram.png" alt="Instagram logo" /></a>
                        <a href="mailto:contact@pathlessproductions.com"><img src="email.png" alt="Mail icon" /></a>
                    </div>
                </li>
                <li className="informationLinks">
                    <ul>
                        <li><a href="/privacy">Privacy Policy</a></li>
                        <li><a href="/pressKit">Press Kit</a></li>
                        <li><a href="/TOS">Terms of Service</a></li>
                        <li><a href="/CookiePolicy">Cookie Policy</a></li>
                    </ul>
                </li>
            </ul>
            <p className="copyright">{'\u00A9'} 2024 Pathless Productions, Inc.</p>
        </footer>
    );
}

export default Footer;
