// JavaScript source code
import './styles/SetupSubscription.css';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';
import { GetAccessToken } from "./Utilities";

function SetupSubscription() {
    const [subFee, setSubFee] = useState(0);
    const [feeSet, setFeeSet] = useState(false);

    const SetFee = (event) => {
        GetAccessToken().then((token) => {

            const subReqOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'accessToken': token, 'paymentAmount': subFee })
            };

            fetch(process.env.REACT_APP_NODE_ADDRESS + '/CreateSubscription', subReqOptions)
                .then((res) => res.json())
                .then((data) => {
                    setFeeSet(true);
                });
        });
    };

    return (
        <div>
            <form className="signUpForm" onSubmit={SetFee}>
                <label htmlFor="subscriptionFee">Subscription Fee:</label><br />
                <input type="number" id="subscriptionFee" name="subscriptionFee" required onChange={(e) => setSubFee(e.target.value)} /><br />
                <input name="submit" type="submit" value="Submit" />
            </form>
            {feeSet && <Navigate to='/'/>}
        </div>
    );
}

export default SetupSubscription;
