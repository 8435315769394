// JavaScript source code
import {
    Body,
    Button,
    Container,
    Head,
    Hr,
    Html,
    Img,
    Link,
    Preview,
    Section,
    Text,
} from '@react-email/components';
import React from 'react';

export function PasswordReset(email, code) {

    return (
        <Html style={docStyle }>
            <Preview>
                There has been a request to change your password.
            </Preview>
            <body>
                <Container>
                    <Img style={imgStyle} src="https://www.devpowered.com/LudareLogo.png" />
                    <Text>
                        Hi!
                    </Text>
                    <Text>
                        Someone has requested to reset the password for this account.
                    </Text>
                    <Text>
                        If this was you, please click the button below to change your password.
                    </Text>
                    <Button style={buttonStyle} href={process.env.REACT_APP_BASE_ADDRESS + "/ResetPassword?email=" + email + "&code=" + code}>
                        Change Password
                    </Button>
                    <Text>
                        If this was not you, then someone maybe be trying to get access to your account. We recommend changing your password and signing out on all platforms. You can also reach out to us and we can help recover your account.
                    </Text>
                </Container>
            </body>
        </Html>
    );
};

const docStyle = {
    width: '800px',
    height: 'fit-content',
    padding: '10px',
    margin: 'auto',
    background: 'white'
}

const buttonStyle = {
    width: '100%',
    height: '40px',
    background: '#ffcf00',
    color: 'black',
    margin: 'auto',
    'text-align': 'center',
    'line-height': '40px',
    'content-align': 'center',
    'padding-top': '15px',
}

const imgStyle = {
    width: '100px',
    height: '100px',
    margin: 'auto'
}