// JavaScript source code
import "./styles/StudioConferenceTools.css"
import { useState, useRef, useEffect } from 'react';
import Cookies from 'js-cookie';
import { IsValid } from './Utilities';

function StudioTools() {
    const [gameId, setGameId] = useState("");
    const [error, setError] = useState(false);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [futurePlaytime, setFuturePlaytime] = useState(0);
    const [showCalculations, setShowCalculations] = useState(false);
    const lastAdvanced = useRef();
    const playtimeToDate = useRef(0);
    const lastMonth = useRef();
    const percentChange = useRef();
    const TVM = useRef(0.05);
    const yearsForward = useRef(0);
    

    function FetchGameData() {
        const reqOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch(process.env.REACT_APP_NODE_ADDRESS + '/CalculatePlaytime?gameId=' + gameId, reqOptions)
            .then((res) => res.json())
            .then((data) => {
                setError(!(data.success));
                if (data.success === true) {
                    setShowCalculations(false);
                    lastAdvanced.current = showAdvanced;
                    setShowAdvanced(false);
                    playtimeToDate.current = data.data.pastPlayed;
                    percentChange.current = data.data.percentChange;
                    lastMonth.current = data.data.lastMonth;
                    UpdateFuturePlaytime();
                }
                else {
                    playtimeToDate.current = 0;
                    lastMonth.current = 0;
                    UpdateFuturePlaytime();
                }
            });
    }

    useEffect(() => {
        if (IsValid(Cookies.get("ResourcesSignUp")) === false || Cookies.get("ResourcesSignUp") === false) {
            window.location.assign("/studios");
        }
    }, []);

    useEffect(() => {
        setShowCalculations(true);
        setShowAdvanced(lastAdvanced.current);
    }, [showCalculations])

    function UpdateFuturePlaytime() {
        var futureHours = 0;
        var percentChangeToUse = 0.20/12;
        if (percentChange.current > 0.0) {
            percentChangeToUse = percentChange.current;
        }

        if (yearsForward.current < 1) {
            futureHours = ((lastMonth.current * 2 * 365) / (percentChangeToUse + (TVM.current / 12)));
        }
        else {
            for (var i = 0; i < yearsForward.current; i++) {
                
                futureHours += ((lastMonth.current * 24 * 365 * Math.pow(Math.pow(1-percentChangeToUse, 12), i)) / Math.pow(1+(TVM.current), i));
            }
        }
        setFuturePlaytime(futureHours);
    }

    return (
        <div className="studioToolsPage">
            <div className="playtimePredictorBlock">
                <h1 className="playtimePredictorTitle">Playtime Predictor</h1>
                <form className="playtimePredictorForm">
                    <div className="gameIdBlock">
                        <label className="gameIdLabel" htmlFor="gameId">Steam ID:</label>
                        <input type="number" id="gameId" className="gameIdField" onChange={(e) => { e.preventDefault(); setGameId(e.target.value); }} />
                        <input type="button" id="advancedSettings" onClick={(e) => { e.preventDefault(); setShowAdvanced(!showAdvanced); }} value="Advanced" />
                    </div>
                    <div className="predictorUpdateButtons">
                        <input type="button" id="calculate" className="calculateButton" onClick={FetchGameData} value="Calculate" />
                    </div>
                    {
                        showAdvanced === true &&
                        <div className="advancedMenuBlock">
                            <div className="monthlyPercentBlock">
                                <label className="monthlyPercentLabel" htmlFor="monthlyPercent">Monthly Percent Loss in Players:</label>
                                <input type="number" id="monthlyPercent" className="monthlyPercentField" defaultValue={percentChange.current} onChange={(e) => { e.preventDefault(); percentChange.current = parseFloat(e.target.value); }} />
                            </div>
                            <div className="tvmBlock">
                                <label className="TVMLabel" htmlFor="TVM">Yearly Interest Rate:</label>
                                <input type="number" id="TVM" className="TVMField" defaultValue={TVM.current} onChange={(e) => { e.preventDefault(); TVM.current = parseFloat(e.target.value) }} />
                            </div>
                            <div className="pedictorYearsBlock">
                                <label className="predictorYearsLabel" htmlFor="yearsCalc">Num Years:</label>
                                <input type="number" id="yearsCalc" className="predictorYearsField" defaultValue={yearsForward.current} onChange={(e) => { e.preventDefault(); yearsForward.current = parseFloat(e.target.value) }} />
                            </div>
                            <input type="button" id="update" className="updateButton" onClick={UpdateFuturePlaytime} value="Update" />
                        </div>
                    }
                </form>
                {
                    showCalculations === true &&
                    <div className="predictorResults">
                        <div className="predictorPastResults">
                            <h1 className="pastResultsTitle">Play Hours To Date</h1>
                            <p className="pastResults">{playtimeToDate.current.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
                        </div>
                        <div className="predictorFutureResults">
                            <h1 className="futureResultsTitle">Play Hours To Go</h1>
                                <p className="futureResults">{futurePlaytime.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</p>
                        </div>
                    </div>
                }
                {
                    error === true &&
                        <div className="errorBlock">
                            <h1 className="errorMsg">Data could not be fetched.</h1>
                        </div>
                }
            </div>

            <div className="allTestimonialsBlock">
                <h1 className="testimonialTitle">Studio Testimonials</h1>
                <div>
                    <div className="testimonialBlock">
                        <p className="testimonialStudioName">Azure Ravens</p>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/jbomWc79jaQ?si=tnkNimHLnkfgaW6G&amp;start=213" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <div className="testimonialBlock">
                        <p className="testimonialStudioName">Nochi Studios</p>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/7jI24W9jAH0?si=qrNeD0j-TbFCRNbw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <div className="testimonialBlock">
                        <p className="testimonialStudioName">Studio Saffron</p>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/B9WRws-MMWs?si=nXSg_EtFzFIdUj_F" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
            <div className="ludareDemoBlock">
                <h1 className="ludareDemoTitle">Unreal Plugin Demo</h1>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/cDmp1-wsLpI?si=Ra_t3MIiwXcokdNC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
    );
}

export default StudioTools;