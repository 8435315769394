// Popup.js
import React from 'react';
import '../../styles/Popup.css';
import { GetGame, IsValid } from "../../Utilities";
import { useEffect } from "react";

const Popup = ({ handleClick, gameData, tagData,  developerInfo}) => {
    const REACT_APP_S3_BASE_URL = process.env.REACT_APP_S3_BASE_URL;

    var { Name, Description, BackgroundUrl, SteamLink, EpicLink } = gameData;
    var { DevName } = developerInfo;

    const fullPhotoUrl = `${REACT_APP_S3_BASE_URL}${BackgroundUrl}`
    return (
        <div className="popup-screen-cover">
            <div className="popup-card">
                <img src={fullPhotoUrl} alt="Game cover" />
                <div className="popup-filter" />

                <div className="popup-text">

                    <div className="popup-card-title">
                        <text>{Name}</text>
                    </div>
                    <div className='popup-text-content'>
                        <div className='popup-card-dev-info'>
                            <div className='popup-card-studio-div'>
                                <p className="popup-card-studio">Studio:</p>
                                <p className="popup-card-studio-name">{gameData.DeveloperNameOverride || DevName}</p>
                            </div>
                            {gameData.PublisherName !== undefined && gameData.PublisherName !== "" && gameData.PublisherName !== null && <div className='popup-card-publisher-div'>
                                <p className="popup-card-publisher">Publisher:</p>
                                <p className="popup-card-publisher-name">{gameData.PublisherName}</p>
                            </div>}
                        </div>
                        <div>
                            {IsValid(tagData) === true && tagData.map((tag, index) => (
                                <span className="popup-tag">{tag}</span>
                            ))}
                        </div>
                        <p className="popup-card-description">{Description}</p>
                    </div>
                    <p className="popup-card-description">
                        
                    </p>
                    <div className="popup-platform-logos">
                        {SteamLink && (
                            <a href={SteamLink} target="_blank" rel="noopener noreferrer">
                                <img
                                src={`${process.env.PUBLIC_URL} steamIcon.png`}
                                alt="Steam"
                                className="platform-logo"
                                style={{ width: 30, height: 30 }}
                                />
                            </a>
                        )}
                       {EpicLink && (
                            <a href={EpicLink} target="_blank" rel="noopener noreferrer">
                                <img
                                src={`${process.env.PUBLIC_URL} epicIcon.svg`}
                                alt="Epic"
                                className="platform-logo"
                                style={{ width: 30, height: 35 }}
                                />
                            </a>
                        )}
                    </div>
                </div>
                <div className="close-btn" onClick={handleClick}><img src={`${process.env.PUBLIC_URL}/xbutton.svg`} /></div>
            </div>
        </div >
    );
};

export default Popup;