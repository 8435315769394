// JavaScript source code
const {
    Body,
    Button,
    Container,
    Head,
    Hr,
    Html,
    Img,
    Preview,
    Section,
    Text,
} = require('@react-email/components');
const React = require('react');

export function ParentalApprovalEmail(username) {
    return (
        <Html style={docStyle }>
            <Preview>
                Your child is requesting access to Ludare.
            </Preview>
            <body>
                <Container>
                    <Img style={imgStyle} src="https://www.devpowered.com/LudareLogo.png" />
                    <Text>
                        Hi!
                    </Text>
                    <Text>
                        Your child is requesting access to Ludare. Ludare is the premier indie game support platform allowing anyone and everyone to support the developers of the things they love by just playing.
                    </Text>
                    <Text>
                        To approve their request, please click below.
                    </Text>
                    <Button style={buttonStyle} href={"https://www.devpowered.com/ParentalApproval?username=" + username}>
                        Approve
                    </Button>
                </Container>
            </body>
        </Html>
    );
};

const docStyle = {
    width: '800px',
    height: 'fit-content',
    padding: '10px',
    margin: 'auto',
    background: 'white'
}

const buttonStyle = {
    width: '100%',
    height: '40px',
    background: '#ffcf00',
    color: 'black',
    margin: 'auto',
    'text-align': 'center',
    'line-height': '40px',
    'content-align': 'center',
    'padding-top': '15px'
}

const imgStyle = {
    width: '100px',
    height: '100px',
    margin: 'auto'
}